@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,700,800,900|Poppins:300,400,500,600,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@media only screen and (max-width: 575px) {
  .container {
    width: 450px;
  }
}
@media only screen and (max-width: 479px) {
  .container {
    width: 320px;
  }
}
details,
header,
nav {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
button,
html,
input,
select,
textarea {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}
a {
  color: #ca3c08;
  text-decoration: none;
}
a:visited {
  color: #ac0404;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  color: #ea9629;
  outline: 0;
}
a:hover {
  text-decoration: underline;
}
b,
strong {
  font-weight: 700;
}
mark {
  background: #ff0;
  color: #000;
}
p {
  margin: 0 0 24px;
}
code {
  font-family: monospace, serif;
  font-size: 14px;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
q {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}
q:after,
q:before {
  content: "";
  content: none;
}
small {
  font-size: smaller;
}
dl {
  margin: 0 20px;
}
dt {
  font-weight: 700;
}
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}
ul {
  list-style-type: square;
}
nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
}
li > ol,
li > ul {
  margin: 0;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
form {
  margin: 0;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
caption {
  font-weight: 400;
  text-align: left;
}
caption {
  font-size: 16px;
  margin: 20px 0;
}
hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
html {
  overflow: hidden;
  overflow-y: auto;
}
body {
  overflow: hidden;
  font-size: 14px;
  line-height: 1.714286;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  color: #ffffff!important;
  font-weight: 400;
}
a {
  transition: all 0.4s ease-in-out 0s;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
caption,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
ul {
  margin: 0 0 15px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  line-height: 1.4074;
  color: #1f1f25;
}
.h1,
h1 {
  font-size: 64px;
}
.h2,
h2 {
  font-size: 54px;
}
.h3,
h3 {
  font-size: 24px;
}
.h4,
h4 {
  font-size: 20px;
}
.h5,
h5 {
  font-size: 18px;
}
.h6,
h6 {
  font-size: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h1,
  h1 {
    font-size: 60px;
  }
  .h2,
  h2 {
    font-size: 52px;
  }
  .h3,
  h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .h1,
  h1 {
    font-size: 58px;
  }
  .h2,
  h2 {
    font-size: 50px;
  }
  .h3,
  h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .h1,
  h1 {
    font-size: 56px;
  }
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-weight: 700;
}
.h4,
.h5,
h4,
h5 {
  font-weight: 600;
}
.h6,
h6 {
  font-weight: 500;
}
a:active,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
}
a:visited {
  color: inherit;
}
button,
input,
select,
textarea {
  background: transparent;
  border: 1px solid #d4d4d4;
  transition: all 0.4s ease-out 0s;
  color: #1d1d24;
}
button:active,
button:focus,
input:active,
input:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  outline: none;
  border-color: #E1232A;
}
input,
select,
textarea {
  width: 100%;
  font-size: 14px;
}
input,
select {
  height: 40px;
  padding: 0 15px;
}
.service.service__style--2:hover .content h3.title,
.service.service__style--2:hover .content p {
  color: #fff;
}
.color-black .mainmenunav ul.mainmenu,
.liststyle,
.mainmenunav ul.mainmenu,
.mainmenunav ul.mainmenu > li > ul.submenu,
ul.tab-style--1 {
  padding: 0;
  margin: 0;
  list-style: none;
}
.header-area .header-wrapper a.rn-btn,
.mainmenunav ul.mainmenu > li > a,
.mainmenunav ul.mainmenu > li > ul.submenu,
.mainmenunav ul.mainmenu > li > ul.submenu li a,
.portfolio,
.portfolio .bg-blr-image,
.portfolio .content .inner .portfolio-button,
.portfolio .thumbnail-inner:before,
.portfolio .thumbnail:after,
.service.service__style--2,
.service.service__style--2 .content h3.title,
.service.service__style--2 .content p,
.service.service__style--2 .icon,
.service.service__style--2:before,
a.rn-btn,
button.rn-btn,
input,
textarea,
ul.social-share li a {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.service.service__style--2 {
  position: relative;
}
.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.hidden {
  display: none;
}
.fix {
  overflow: hidden;
}
.wrapper {
  position: relative;
}
.theme-color {
  color: #E1232A;
}
.bg_color--1 {
  background: #fff;
}
.bg_color--5 {
  background: #f8f9fc;
}
.bg_image--1 {
  background-image: url(/static/media/bg-image-1.a0683f2e.jpg) !important;
}
.bg_image--25 {
  background-image: url(/static/media/bg-image-25.e12a7844.jpg) !important;
}
p {
  font-size: 18px;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}
p:last-child {
  margin-bottom: 0;
}
.row {
  -webkit-justify-content: center;
  justify-content: center;
}
.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--35 {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--35 > [class*="col-"],
.row--35 > [class*="col"] {
  padding-left: 35px;
  padding-right: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--35 > [class*="col-"],
  .row--35 > [class*="col"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col-"],
  .row--35 > [class*="col"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col-"],
  .row--35 > [class*="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--35 > [class*="col-"],
  .row--35 > [class*="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); /* IE 8 */
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); /* IE 8 */
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); /* IE 8 */
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); /* IE 8 */
}
[data-black-overlay],
[data-black-overlay] > *,
[data-black-overlay] > div {
  position: relative;
  z-index: 1;
}
[data-black-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
[data-black-overlay]:before {
  background-color: #00010c;
}
[data-black-overlay="1"]:before {
  opacity: 0.1;
}
[data-black-overlay="2"]:before {
  opacity: 0.2;
}
[data-black-overlay="3"]:before {
  opacity: 0.3;
}
[data-black-overlay="4"]:before {
  opacity: 0.4;
}
[data-black-overlay="5"]:before {
  opacity: 0.5;
}
[data-black-overlay="6"]:before {
  opacity: 0.6;
}
[data-black-overlay="7"]:before {
  opacity: 0.7;
}
[data-black-overlay="8"]:before {
  opacity: 0.8;
}
[data-black-overlay="9"]:before {
  opacity: 0.9;
}
[data-black-overlay="10"]:before {
  opacity: 1;
}
.thumbnail img {
  border-radius: 6px;
}
.mb-dec--30 {
  margin-bottom: -30px;
}
.pl--20 {
  padding-left: 20px;
}
.ptb--30 {
  padding: 30px 0;
}
.mt--30 {
  margin-top: 30px !important;
}
.mb--30 {
  margin-bottom: 30px;
}
.mt--40 {
  margin-top: 40px !important;
}
.plr--50 {
  padding: 0 50px;
}
.mb--50 {
  margin-bottom: 50px;
}
.ml--50 {
  margin-left: 50px;
}
.ptb--120 {
  padding: 120px 0;
}
@media only screen and (max-width: 767px) {
  .ptb--120 {
    padding: 78px 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120 {
    padding: 80px 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt_md--20 {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb_sm--0 {
    margin-bottom: 0 !important;
  }
  .plr_sm--20 {
    padding: 0 20px !important;
  }
  .mt_sm--20 {
    margin-top: 20px !important;
  }
  .mb_sm--20 {
    margin-bottom: 20px !important;
  }
  .mb_sm--0 {
    margin-bottom: 0;
  }
}
.header-area .header-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 50px 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 24px;
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper {
    padding: 15px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper {
    padding: 30px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .header-wrapper {
    padding: 30px 0;
  }
}
.header-area .header-wrapper a.rn-btn {
  padding: 0 25px;
  height: 46px;
  display: inline-block;
  line-height: 42px;
  border: 2px solid #b1b4c1;
  border-radius: 6px;
  color: #c6c9d8;
  font-size: 14px;
  position: relative;
  z-index: 2;
}
.header-area .header-wrapper a.rn-btn.theme-color {
  border-color: #E1232A;
  color: #E1232A;
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper a.rn-btn {
    padding: 0 11px;
    height: 39px;
    line-height: 33px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 575px) {
  .header-area .header-wrapper a.rn-btn {
    height: 34px;
    line-height: 29px;
  }
}
.header-area .header-wrapper a.rn-btn:before {
  display: none;
}
.header-area .header-wrapper a.rn-btn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  border: 2px solid #E1232A;
  background: #E1232A;
  border-radius: 6px;
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper a.rn-btn {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .logo a img {
    width: 120px;
  }
}
.header-area.color-black a.rn-btn {
  border: 2px solid rgba(29, 29, 36, 0.5);
  color: #1d1d24;
}
.header-area.header--fixed {
  position: absolute;
  top: 0;
  z-index: 999;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  background-color: #0000002c;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area.header--fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header--fixed {
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .header-area.header--fixed {
    padding: 10px 30px;
  }
}
.header-area.header--fixed .header-wrapper {
  padding: 20px 0;
  transition: 0.3s;
}
.header-area.header--fixed .header-wrapper .logo a img {
  transition: 0.5s;
}
.header-area.header--fixed .header-wrapper .mainmenunav ul.mainmenu > li > a {
  position: relative;
}
.header-area.header--fixed
  .header-wrapper
  .mainmenunav
  ul.mainmenu
  > li
  > a:after {
  position: absolute;
  content: "";
  left: 0;
  width: 0;
  height: 3px;
  background: #E1232A;
  transition: 0.3s;
  bottom: 0;
}
.header-area.header--fixed
  .header-wrapper
  .mainmenunav
  ul.mainmenu
  > li.is-current
  a {
  color: #E1232A;
}
.header-area.header--fixed
  .header-wrapper
  .mainmenunav
  ul.mainmenu
  > li.is-current
  a:after {
  width: 100%;
}
.header-area.header--fixed.default-color.sticky
  .mainmenunav
  ul.mainmenu
  > li
  > a {
  color: #1f1f25;
}
.header-area.header--fixed.default-color.sticky
  .mainmenunav
  ul.mainmenu
  > li.is-current
  a {
  color: #E1232A;
}
.header-area.header--fixed.default-color.sticky
  .mainmenunav
  ul.mainmenu
  > li.is-current
  a:after {
  width: 100%;
}
.header-area.header--fixed.sticky {
  position: fixed;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.header-area.header--fixed.sticky .header-wrapper {
  padding: 0;
}
.header-area.header--fixed.sticky .header-wrapper a.rn-btn {
  color: #1f1f25;
  border-color: #1f1f25;
}
.header-area.header--fixed.sticky .header-wrapper a.rn-btn:hover {
  color: #fff;
  border-color: #E1232A;
}
.header-area.header--fixed.sticky .logo a img {
  height: 50px;
}
.header-area.color-black .humberger-menu span.text-white,
.header-area.header--fixed.sticky .humberger-menu span.text-white {
  color: #1f1f25 !important;
}
.header-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav {
    position: absolute;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav {
    position: absolute;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -61vw;
    z-index: 99;
    padding: 55px;
    background: #fff;
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    display: block;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -61vw;
    z-index: 99;
    padding: 55px;
    background: #fff;
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    display: block;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li {
    margin: 0;
    padding: 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li {
    margin: 0;
    padding: 10px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown {
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown,
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a {
    position: relative;
  }
  .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a:after {
    width: 0;
    height: 0;
    border-color: #1f1f25 transparent transparent;
    border-style: solid;
    border-width: 7px 7px 0;
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a.open {
    color: #E1232A;
  }
  .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a.open:after {
    width: 0;
    height: 0;
    border-color: transparent transparent #E1232A;
    border-style: solid;
    border-width: 0 7px 7px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a {
    position: relative;
  }
  .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a:after {
    width: 0;
    height: 0;
    border-color: #1f1f25 transparent transparent;
    border-style: solid;
    border-width: 7px 7px 0;
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a.open {
    color: #E1232A;
  }
  .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a.open:after {
    width: 0;
    height: 0;
    border-color: transparent transparent #E1232A;
    border-style: solid;
    border-width: 0 7px 7px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    padding: 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    padding: 0;
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu {
    position: relative;
    height: 0;
    transition: 0.3s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    background: transparent;
    box-shadow: inherit;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu {
    position: relative;
    height: 0;
    transition: 0.3s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    background: transparent;
    box-shadow: inherit;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li {
    padding: 3px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li {
    padding: 3px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li a {
    padding: 2px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li a {
    padding: 2px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu.active {
    height: 100%;
    padding: 12px 0;
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu.active {
    height: 100%;
    padding: 12px 0;
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #1f1f25;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #1f1f25;
  }
}
.header-area .header-wrapper .close-menu {
  position: fixed;
  top: 12px;
  opacity: 0;
  visibility: hidden;
  right: 29px;
  z-index: -10;
}
.header-area .header-wrapper.menu-open .close-menu {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}
.header-area .header-wrapper.menu-open .close-menu span {
  color: #000;
  font-size: 30px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper.menu-open .mainmenunav ul.mainmenu {
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper.menu-open .mainmenunav ul.mainmenu {
    right: 0;
  }
}
@media only screen and (max-width: 575px) {
  .header-area .header-wrapper.menu-open .mainmenunav ul.mainmenu {
    width: 86vw;
    padding: 54px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-style-two .header-wrapper {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area.header-style-two .header-wrapper {
    padding: 15px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-style-two .header-wrapper .logo a img {
    width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .header-area.header-style-two .header-wrapper .logo a img {
    width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .header-area.header-style-two .header-wrapper ul.social-share li {
    margin: 0;
  }
}
.humberger-menu span {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .humberger-menu span {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .humberger-menu span {
    font-size: 28px;
  }
}
.header-wrapper.menu-open .humberger-menu span {
  opacity: 0;
}
.mainmenunav ul.mainmenu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mainmenunav ul.mainmenu > li {
  margin: 0 15px;
  position: relative;
}
.mainmenunav ul.mainmenu > li > a {
  color: #c6c9d8;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  display: inline-block;
}
.mainmenunav ul.mainmenu > li > ul.submenu {
  min-width: 240px;
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 12px 0;
  border-radius: 4px;
}
.mainmenunav ul.mainmenu > li > ul.submenu li a {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;
  font-size: 14px;
  display: block;
  color: #1f1f25;
  margin: 0 10px;
  border-radius: 3px;
}
.mainmenunav ul.mainmenu > li > ul.submenu li:hover a {
  color: #E1232A;
  background: rgba(249, 0, 77, 0.07);
}
.mainmenunav ul.mainmenu > li:hover > ul.submenu {
  opacity: 1;
  visibility: visible;
}
.mainmenunav ul.mainmenu > li:hover > a {
  color: #E1232A;
}
.mainmenunav ul.mainmenu > li:first-child {
  margin-left: 0;
}
.mainmenunav ul.mainmenu > li:last-child {
  margin-right: 0;
}
.humberger-menu span.text-white {
  color: #c6c9d8 !important;
}
.color-black .mainmenunav ul.mainmenu > li > a {
  color: #1d1d24;
}
.color-black .mainmenunav ul.mainmenu > li:hover > a {
  color: #E1232A;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}
@media only screen and (max-width: 767px) {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
  .about-container {
    max-width: 900px !important;
  }
}
.backto-top > div {
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 49px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  z-index: 999 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.backto-top svg {
  font-size: 14px !important;
  color: #222;
  width: 27px;
  height: 27px;
}
.section-title {
  margin-top: -20px;
}
.section-title h2.title {
  font-size: 60px;
  margin-bottom: 8px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2.title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title h2.title {
    font-size: 36px;
    margin-bottom: 7px;
  }
}
.section-title p {
  font-size: 18px;
  line-height: 30px;
  color: rgba(29, 29, 36, 0.75);
}
.section-title p a {
  color: #1d1d24;
  font-weight: 500;
}
.section-title p a:hover {
  color: #E1232A;
}
@media only screen and (max-width: 767px) {
  .section-title p br {
    display: none;
  }
}
.section-title h3 {
  font-size: 36px;
  line-height: 26px;
}
@media only screen and (max-width: 767px) {
  .section-title h3 {
    font-size: 26px;
    margin-bottom: 8px;
  }
}
.section-title.service-style--3 p {
  padding: 0 21%;
  font-weight: 300;
  color: #717173;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.service-style--3 p {
    padding: 0 7%;
  }
}
@media only screen and (max-width: 767px) {
  .section-title.service-style--3 p {
    padding: 0;
  }
}
.section-title.service-style--3.text-left p {
  padding: 0 3% 0 0;
}
@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 36px;
    margin-bottom: 7px;
  }
}
a.rn-btn,
button.rn-btn {
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid #E1232A;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: #E1232A;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
a.rn-btn:hover,
button.rn-btn:hover {
  border: 2px solid #E1232A;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  background: #E1232A;
}
a.rn-button-style--2,
button.rn-button-style--2 {
  color: #c6c9d8;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #b1b4c1;
  padding: 15px 40px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  transition: 0.3s;
}
.newbtnsonglist{
  padding: 0 23px;
  margin-right: 10px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid #E1232A;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: #E1232A;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.newbtnsonglist:hover,
.newbtnsonglist:focus,
.newbtnsonglist:active{
  border: 2px solid #E1232A;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  background: #E1232A;
}

.watchhere:hover{
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #E1232A;
  cursor: pointer;
}

.newbtnsonglist1{
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid #E1232A;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: #E1232A;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.newbtnsonglist1:hover,
.newbtnsonglist1:focus,
.newbtnsonglist1:active{
  border: 2px solid #E1232A;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  background: #E1232A;
}


.newbtnsonglist2{
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid #E1232A;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: #E1232A;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: bold!important;
}
.newbtnsonglist2:hover,
.newbtnsonglist2:focus,
.newbtnsonglist2:active{
  border: 2px solid #E1232A;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  background: #E1232A;
}


.newbtnsonglist23{
  padding: 0 3px;
  height: 40px;
  margin-right: 18px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: #ffffff;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.newbtnsonglist23:hover,
.newbtnsonglist23:focus,
.newbtnsonglist23:active{
  border: 2px solid #FFF;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #000;
  background: #FFF;
}

.newbtnsonglist3{
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid #E1232A;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: #E1232A;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.newbtnsonglist3:hover{
  border: 2px solid #E1232A;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  background: #E1232A;
}
.newbtnsonglist3:active{
  border: 2px solid #E1232A;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #fff;
  background: #E1232A;
}

.song-list-container {
  width: 100%;
  margin: auto;
}

.song-list {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.song-header {
  background-color: #333!important;
  color: #fff!important;
  font-weight: bold;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.header-title {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.header-artist {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.song {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.song-title {
  -webkit-flex: 1 1;
          flex: 1 1;
  color:#000!important;
}

.song-artist {
  -webkit-flex: 1 1;
          flex: 1 1;
  color:#000!important;
}


@media only screen and (max-width: 767px) {
  a.rn-button-style--2,
  button.rn-button-style--2 {
    padding: 9px 25px;
  }
  
#twitchembededmobile {
  display: none;
  
  }
.newbtnsonglist23{
  margin-right: 18px;
}
}
a.rn-button-style--2.btn-primary-color:hover,
button.rn-button-style--2.btn-primary-color:hover {
  border: 2px solid #fff;
  background: #fff;
  color: #fff;
}
a.rn-button-style--2.btn-solid,
button.rn-button-style--2.btn-solid {
  border: 2px solid #fff;
  background: #e12329b2;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);

}
a.rn-button-style--2:hover,
button.rn-button-style--2:hover {
  background: transparent;
  color: #ffffff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}


a.rn-button-style--3.btn-primary-color:hover,
button.rn-button-style--3.btn-primary-color:hover {
  border: 2px solid #fff;
  background: #fff;
  color: #fff;
}
a.rn-button-style--3.btn-solid,
button.rn-button-style--3.btn-solid {
  border: 2px solid #fff;
  background: #e12329b2;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);

}
a.rn-button-style--3:hover,
button.rn-button-style--3:hover {
  background: transparent;
  color: #ffffff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
ul.social-share {
  padding: 0;
  margin: -6px;
}
ul.social-share li {
  margin: 6px;
}
ul.social-share li a {
  width: 40px;
  display: inline-block;
  height: 40px;
  border: 2px solid rgba(198, 201, 216, 0.75);
  line-height: 35px;
  color: rgba(198, 201, 216, 0.75);
  border-radius: 100%;
  text-align: center;
  font-size: 14px;
}
ul.social-share li:hover a {
  background: #E1232A;
  border-color: #fff;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
ul.social-share.rn-lg-size {
  margin-top: 0 !important;
}
ul.social-share.rn-lg-size li a {
  width: 40px;
  height: 40px;
  line-height: 33px;
  font-size: 16px;
}
ul.social-share.social-style--2 li a {
  border: 0;
  width: 30px;
  height: auto;
  text-align: left;
  line-height: inherit;
  font-size: 16px;
}
ul.social-share.social-style--2 li:hover a {
  background: transparent;
  box-shadow: none;
}
ul.social-share.social-style--2.color-black li a {
  color: #1d1d24;
  opacity: 0.8;
}
ul.social-share.social-style--2.color-black li a:hover {
  color: #E1232A;
  opacity: 1;
}
.footer-style-2 p {
  color: #c6c9d8;
  font-size: 14px;
  opacity: 0.75;
}
.portfolio {
  position: relative;
  min-height: 260px;
  width: 100%;
  padding-top: 35%;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .portfolio {
    min-height: 255px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio {
    min-height: 190px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio {
    min-height: 216px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio {
    min-height: 274px;
  }
}
.portfolio .thumbnail-inner {
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  z-index: 9!important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
}
.portfolio .thumbnail-inner:before {
  background-color: #5687af8a;
  background-image: linear-gradient(#5687af8a 10%, #000);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
}
.portfolio .thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  border-radius: 5px;
  background-color: #0a0a0a;
  -webkit-transform: scale(1.13) translateZ(0);
  transform: scale(1.13) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  z-index: 4;
}
.portfolio .thumbnail.image-1 {
  background-image: url(/static/media/portfolio-1.60cbc39b.jpg);
}
.portfolio .thumbnail.image-2 {
  background-image: url(/static/media/portfolio-2.b76f5afc.jpg);
}
.portfolio .thumbnail.image-3 {
  background-image: url(/static/media/portfolio-3.f950e5a3.jpg);
}
.portfolio .thumbnail.image-4 {
  background-image: url(/static/media/portfolio-4.8eddc074.jpg);
}
.portfolio .thumbnail.image-5 {
  background-image: url(/static/media/portfolio-5.c728612c.jpg);
}
.portfolio .thumbnail.image-6 {
  background-image: url(/static/media/portfolio-6.97604fed.jpg);
}
.portfolio .thumbnail.image-7 {
  background-image: url(/static/media/portfolio-7.30e38698.jpg);
}
.portfolio .thumbnail:after {
  background-color: rgba(45, 45, 45, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: " ";
  z-index: 1;
  display: block;
  border-radius: 5px;
  background-color: rgba(25, 25, 25, 0.37);
}
.postButtons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.portfolio .bg-blr-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  background-color: #0a0a0a;
  -webkit-transform: translateY(38px);
  transform: translateY(38px);
  opacity: 0;
  z-index: 1;
}
.portfolio .bg-blr-image.image-1 {
  background-image: url(/static/media/portfolio-1.60cbc39b.jpg);
}
.portfolio .bg-blr-image.image-2 {
  background-image: url(/static/media/portfolio-2.b76f5afc.jpg);
}
.portfolio .bg-blr-image.image-3 {
  background-image: url(/static/media/portfolio-1.60cbc39b.jpg);
}
.portfolio .bg-blr-image.image-4 {
  background-image: url(/static/media/portfolio-5.c728612c.jpg);
}
.portfolio .content {
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  max-width: 100% !important;
  z-index: 10;
  padding: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio .content {
    left: 20px;
    right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio .content {
    left: 20px;
    right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio .content {
    left: 20px;
    right: 20px;
  }
}
.portfolio .content .inner p {
  color: #c6c9d8;
  font-size: 14px;
}
.portfolio .content .inner h4 {
  font-size: 24px;
  line-height: 36px;
}
.portfolio .content .inner h4 a {
  color: #fff;
}
.portfolio .content .inner .portfolio-button {
  margin-top: 8px;
  transition: 0.7s;
}
@media only screen and (max-width: 575px) {
  .portfolio .content .inner .portfolio-button {
    margin-top: 25px;
  }
}
.portfolio .content .inner .portfolio-button a.rn-btn {
  color: #fff;
  border-color: hsla(0, 0%, 100%, 0.3);
}
.portfolio .content .inner .portfolio-button a.rn-btn:hover {
  border-color: #fff;
}
.portfolio.text-center .content {
  position: absolute;
  bottom: 20px;
  left: 40px;
  max-width: 80% !important;
  z-index: 10;
  padding: 0;
  right: 40px;
  margin: 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio.text-center .content {
    left: 20px;
    right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio.text-center .content {
    left: 15px;
    right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio.text-center .content {
    left: 10px;
    right: 10px;
  }
}
.portfolio:hover .thumbnail-inner {
  -webkit-transform: scale(1.08) translateZ(0);
  transform: scale(1.08) translateZ(0);
}
.portfolio:hover .thumbnail-inner:before {
  opacity: 0.85;
}
.portfolio:hover .thumbnail {
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
}
.portfolio:hover .thumbnail:after {
  background-color: rgba(25, 25, 25, 0.24);
}
.portfolio:hover .bg-blr-image {
  opacity: 0;
  z-index: 9;
}
.service.service__style--2 {
  padding: 30px 35px;
  z-index: 2;
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--2 {
    padding: 30px 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 {
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--2 {
    padding: 30px 14px;
  }
}
@media only screen and (max-width: 575px) {
  .service.service__style--2 {
    padding: 30px 18px;
    margin-top: 30px;
  }
}
.service.service__style--2:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-image: linear-gradient(90deg, #e1232991, #E1232A);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
}
.service.service__style--2 .icon {
  font-size: 54px;
  font-weight: 400;
  margin-bottom: 23px;
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #E1232A;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 .icon {
    margin-bottom: 11px;
  }
}
@media only screen and (max-width: 575px) {
  .service.service__style--2 .icon {
    color: #fff;
  }
}
.service.service__style--2 .icon svg {
  stroke-width: 1 !important;
}
.service.service__style--2 .content h3.title {
  margin-bottom: 19px;
  font-weight: 500;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--2 .content h3.title {
    font-size: 19px;
  }
}
@media only screen and (max-width: 767px) {
  .service.service__style--2 .content h3.title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 .content h3.title {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 575px) {
  .service.service__style--2 .content h3.title {
    color: #fff;
  }
}
.service.service__style--2 .content h3.title a {
  color: inherit;
}
@media only screen and (max-width: 575px) {
  .service.service__style--2 .content h3.title a {
    color: #fff;
  }
}
.service.service__style--2 .content p {
  color: rgba(29, 29, 36, 0.75);
}
@media only screen and (max-width: 575px) {
  .service.service__style--2 .content p {
    color: #fff;
  }
}
.service.service__style--2:hover {
  box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.service.service__style--2:hover:before {
  opacity: 1;
  visibility: visible;
}
.service.service__style--2:hover .icon {
  color: #fff;
}
.text-center .service.service__style--2,
.text-left .service.service__style--2 {
  padding: 60px 45px;
}
@media only screen and (max-width: 767px) {
  .text-center .service.service__style--2,
  .text-left .service.service__style--2 {
    padding: 30px 14px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .text-center .service.service__style--2,
  .text-left .service.service__style--2 {
    padding: 30px 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-center .service.service__style--2,
  .text-left .service.service__style--2 {
    padding: 30px 14px;
  }
}
.creative-service-wrapper .row.creative-service a {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}
.creative-service-wrapper .row.creative-service .service.service__style--2 {
  margin-top: 30px;
  background: #f6f6f6;
}
.slide .inner {
  padding-top: 100px;
}
@media only screen and (max-width: 767px) {
  .slide .inner {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .slide .inner {
    padding-top: 30px;
  }
}
.slide .inner h1.title {
  font-size: 125px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  line-height: 130px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide .inner h1.title {
    font-size: 100px;
    line-height: 112px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .inner h1.title {
    font-size: 90px;
    line-height: 103px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .inner h1.title {
    font-size: 70px;
    line-height: 82px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .slide .inner h1.title {
    font-size: 50px;
    line-height: 70px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 575px) {
  .slide .inner h1.title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}
.slide.slider-style-3 .inner > span {
  color: #1f1f25;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .slide.slider-style-3 .inner > span {
    font-size: 13px;
    margin-bottom: 10px;
  }
}
.slide.slider-style-3 .inner h1.title {
  color: #1f1f25;
  font-size: 75px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  line-height: 90px;
  text-transform: inherit;
  width: 70%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slider-style-3 .inner h1.title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-3 .inner h1.title {
    font-size: 60px;
    line-height: 77px;
    width: 85%;
  }
}
@media only screen and (max-width: 767px) {
  .slide.slider-style-3 .inner h1.title {
    font-size: 42px;
    line-height: 57px;
    width: 100%;
  }
}
.slide.slider-style-3 .inner h1.title span {
  color: #E1232A;
}
.personal-portfolio-slider {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .personal-portfolio-slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-portfolio-slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-portfolio-slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.7;
  }
}
@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 {
    padding: 100px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 {
    padding: 150px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.personal-portfolio-slider.slider-style-3 {
    padding: 150px 0;
    min-width: auto;
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner {
    padding-top: 56px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner {
    padding-top: 76px;
  }
}
@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title {
    font-size: 32px;
    line-height: 49px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title {
    font-size: 53px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title {
    font-size: 58px;
    line-height: 75px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title {
    font-size: 54px;
    line-height: 68px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2 {
    font-size: 54px;
    line-height: 1.3;
  }
}
@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2 {
    font-size: 32px;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2 {
    font-size: 53px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2 {
    font-size: 58px;
    line-height: 75px;
  }
}
.slide .service {
  margin-top: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .service {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide .service {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .service {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .slide .service {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .slide .service {
    margin-top: 0;
  }
}
.slider-paralax {
  height: 950px;
}
@media only screen and (max-width: 767px) {
  .slider-paralax {
    height: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-paralax {
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-paralax {
    height: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-paralax {
    height: auto;
  }
}
@-webkit-keyframes customOne {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes customOne {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.thumbnail img {
  border-radius: 5px;
  box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
}
ul.brand-style-2 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575px) {
  ul.brand-style-2 {
    margin: 0 -15px;
  }
}
ul.brand-style-2 li {
  padding: 25px;
  -webkit-flex-basis: 20%;
          flex-basis: 20%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  ul.brand-style-2 li {
    -webkit-flex-basis: 33.33%;
            flex-basis: 33.33%;
    padding: 17px 10px;
  }
}
@media only screen and (max-width: 575px) {
  ul.brand-style-2 li {
    -webkit-flex-basis: 33.33%;
            flex-basis: 33.33%;
    padding: 0 15px;
    margin-bottom: 16px;
  }
}
ul.brand-style-2 img {
  opacity: 1;
  transition: 0.3s;
  max-height: 120px;
  max-width: 150px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  ul.brand-style-2 img {
    max-height: 70px;
    max-width: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  ul.brand-style-2 img {
    max-width: 129px;
  }
}
@media only screen and (max-width: 575px) {
  ul.brand-style-2 img {
    max-height: none;
    max-width: 100%;
  }
}
ul.brand-style-2 img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 1;
}
ul.tab-style--1 {
  display: -webkit-flex;
  display: flex;
  margin: 0 -20px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  ul.tab-style--1 {
    margin: 0 -10px;
  }
}
ul.tab-style--1 li {
  position: relative;
  margin: 0 20px;
  display: inline-block;
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  ul.tab-style--1 li {
    margin: 0 10px;
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  ul.tab-style--1 li {
    margin-bottom: 10px;
  }
}
ul.tab-style--1 li:before {
  position: absolute;
  content: "";
  width: 30px;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  height: 2px;
  bottom: 0;
}
.single-tab-content {
  padding-top: 20px;
}
.single-tab-content p {
  font-weight: 300;
  color: #717173;
  font-size: 18px;
  line-height: 30px;
}
.single-tab-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.single-tab-content ul li {
  color: #7e7e7e;
  font-size: 16px;
  line-height: inherit;
  margin-bottom: 15px;
  font-weight: 300;
}
.single-tab-content ul li a {
  font-weight: 500;
  display: block;
  color: #717173;
}
.single-tab-content ul li a span {
  font-weight: 300;
}
.contact-form--1 label {
  display: block;
  margin-bottom: 0;
}
.contact-form--1 input,
.contact-form--1 textarea {
  display: block;
  width: 100%;
  padding: 0 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.3s ease;
  height: 50px;
  line-height: 46px;
  margin-bottom: 20px;
  outline: none;
  color: #1f1f25;
  font-size: 15px;
  letter-spacing: 0.1px;
}
.contact-form--1 input:focus,
.contact-form--1 textarea:focus {
  border-color: #008bff;
}
.contact-form--1 textarea {
  height: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-inner {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .about-inner {
    padding-top: 40px;
  }
}
.about-inner .section-title h2.title {
  margin-bottom: 8px;
}
.about-inner .section-title p.description {
  font-weight: 300;
  color: #717173;
}
.about-inner .section-title p.description a {
  color: #1d1d24;
}
.about-inner .section-title p.description a:hover {
  color: #008bff;
}
.about-wrapper .thumbnail {
  position: relative;
  z-index: 2;
  text-align: center;
}
.about-wrapper .thumbnail img {
  border-radius: 50%;
  width: 170px !important;
}
.active-dark {
  overflow: hidden;
  font-size: 14px;
  line-height: 1.714286;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  color: #ffffff!important;
  font-weight: 400;
  background-image: url(https://img.freepik.com/free-vector/luxury-dark-seamless-pattern_1048-10342.jpg)!important;
  background-repeat: repeat;
  position: relative;
}
.facebook-feed a{
  color: #000 !important;
}
.fb-xfbml-parse-ignore a{
  color: #000 !important;
}

@-webkit-keyframes zoomInOut {
  0% {
    background-size: 100%; /* Initial size */
  }
  50% {
    background-size: 110%; /* Zoom in to 120% at 50% of the animation */
  }
  100% {
    background-size: 100%; /* Return to initial size */
  }
}

@keyframes zoomInOut {
  0% {
    background-size: 100%; /* Initial size */
  }
  50% {
    background-size: 110%; /* Zoom in to 120% at 50% of the animation */
  }
  100% {
    background-size: 100%; /* Return to initial size */
  }
}

.active-dark [data-black-overlay="6"]:before,
.active-dark [data-black-overlay]:before {
  display: none;
}
.active-dark .bg_image--1 {
  background: radial-gradient(90% 100% at 50% 50%, #00000000 99%, #000000 99%)!important;

}
.active-dark .bg_color--1 {
  background: radial-gradient(90% 100% at 50% 50%, #00000000 99%, #000000 99%);
  
}
.active-dark .bg_color--5 {
  background: #101010;
}
.active-dark ul.brand-style-2 img {
  -webkit-filter: invert(1);
  filter: invert(1);
}
.active-dark .h1,
.active-dark .h2,
.active-dark .h3,
.active-dark .h4,
.active-dark .h5,
.active-dark .h6,
.active-dark h1,
.active-dark h2,
.active-dark h3,
.active-dark h4,
.active-dark h5,
.active-dark h6 {
  color: #fff;
}
.active-dark .about-inner .section-title p.description,
.active-dark .section-title p,
.active-dark .service.service__style--2 .content p,
.active-dark .single-tab-content ul li,
.active-dark .single-tab-content ul li a,
.active-dark .slide.slider-style-3 .inner > span {
  color: rgba(198, 201, 216, 0.75);
}
.active-dark ul.tab-style--1 li:before {
  background: rgba(198, 201, 216, 0.75);
}
.active-dark .color-black .mainmenunav ul.mainmenu > li > a,
.active-dark .header-area.color-black a.rn-btn,
.active-dark .section-title p a,
.active-dark .service.service__style--2:hover .content h3.title,
.active-dark .service.service__style--2:hover .content p,
.active-dark .slide.slider-style-3 .inner h1.title,
.active-dark ul.social-share.social-style--2.color-black li a,
.active-dark ul.tab-style--1 li {
  color: #fff;
}
.active-dark
  .creative-service-wrapper
  .row.creative-service
  .service.service__style--2 {
  background: #191919;
}
.active-dark .contact-form--1 input,
.active-dark .contact-form--1 textarea,
.active-dark .header-area.color-black a.rn-btn {
  border: 2px solid hsla(0, 0%, 100%, 0.2);
}
.active-dark .header-area.color-black a.rn-btn:hover {
  border-color: #008bff;
}
.active-dark .header-area.header--fixed.sticky {
  background: #191919;
}
.active-dark .header-area.header--fixed.sticky .header-wrapper a.rn-btn {
  color: #fff;
  border-color: #fff;
}
.active-dark .header-area.header--fixed.sticky .header-wrapper a.rn-btn:hover {
  color: #fff;
  border-color: #008bff;
}
.active-dark .bg_image--25 {
  background-color: #101010;
  background-image: url(/static/media/portrait.30e38698.jpg) !important;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.active-dark .backto-top > div {
  background-color: #000;
}
.active-dark .backto-top svg {
  color: #fff;
}
.active-dark .contact-form--1 input,
.active-dark .contact-form--1 textarea {
  color: rgba(198, 201, 216, 0.75);
}
.active-dark .personal-portfolio-slider:before {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .active-dark .header-style-two .humberger-menu span.text-white {
    color: #c6c9d8 !important;
  }
  .active-dark .header-area .header-wrapper .mainmenunav ul.mainmenu {
    background: #101010;
  }
  .active-dark
    .header-area.header--fixed.sticky
    .humberger-menu
    span.text-white,
  .active-dark .header-area .header-wrapper.menu-open .close-menu span {
    color: #c6c9d8 !important;
  }
  .active-dark .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #fff;
  }
  .active-dark
    .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a:after {
    border-color: #fff transparent transparent;
  }
  .active-dark
    .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a.open:after {
    border-color: transparent transparent #008bff;
  }
}
@media only screen and (max-width: 767px) {
  .active-dark .header-style-two .humberger-menu span.text-white {
    color: #c6c9d8 !important;
  }
  .active-dark .header-area .header-wrapper .mainmenunav ul.mainmenu {
    background: #101010;
  }
  .active-dark
    .header-area.header--fixed.sticky
    .humberger-menu
    span.text-white,
  .active-dark .header-area .header-wrapper.menu-open .close-menu span {
    color: #c6c9d8 !important;
  }
  .active-dark .header-area .header-wrapper .mainmenunav ul.mainmenu li a,
  .active-dark .service.service__style--2 .content p {
    color: #fff;
  }
  .active-dark
    .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a:after {
    border-color: #fff transparent transparent;
  }
  .active-dark
    .header-area
    .header-wrapper
    .mainmenunav
    ul.mainmenu
    li.has-droupdown
    > a.open:after {
    border-color: transparent transparent #008bff;
  }
}
.card-main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.card {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 15px;
  padding: 20px 30px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}


.card2 {
  background-color: rgb(255, 255, 255);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 15px;
  padding: 20px 30px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}


.card3 {
  background-color: rgb(255, 255, 255);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 15px;
  padding: 20px 30px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}

.card {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(33.33% - 20px);
          flex-basis: calc(33.33% - 20px);
}

.card2 {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(66% - 20px);
          flex-basis: calc(66% - 20px); /* Adjust width to be wider than the first card */
}

.card3 {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(100% - 20px);
          flex-basis: calc(100% - 20px); /* Adjust width to be wider than the first card */
}


/* Your existing styles for card-image, card-description, and card-button */



.card-image {
  overflow: hidden; /* Hide overflow to prevent scrollbars */
  border-radius: 10px; /* Add border radius to the image container */
  width: 100%;
}

.card-image iframe {
  width: 100%;
  height: 100%;
  border: 0; /* Remove border from the iframe */
}

.card-description h4 {
  color: rgb(39, 39, 39);
  font-size: 17px;
  padding-top: 10px;
  margin: 0;
}

.card-description p {
  color: rgb(100, 100, 100);
  font-size: 14px;
  padding-top: 8px;
  margin: 0;
}

.card-button {
  margin-top: auto; /* Push the button to the bottom of the card */
}

.card-button button {
  border-radius: 50px;
  width: 100%;
  border: 0;
  color: white;
  height: 40px;
  background-color: #E1232A;
  transition: all ease-in-out 0.3s;
}

.card-button button:hover {
  cursor: pointer;
  background-color: #E1232A;
  transition: all ease-in-out 0.3s;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

/* Media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
  .card {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 20px);
            flex-basis: calc(100% - 20px); /* Adjust width to fit two cards in a row for smaller screens */
  }
  .card2 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 20px);
            flex-basis: calc(100% - 20px); /* Adjust width to fit two cards in a row for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .card {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 20px);
            flex-basis: calc(100% - 20px); /* Adjust width to fit one card in a row for even smaller screens */
  }
  .card2 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 20px);
            flex-basis: calc(100% - 20px); /* Adjust width to fit one card in a row for even smaller screens */
  }
}
.read-more-state {
  display: none;
}

.read-more-wrap {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
}

.read-more-state:checked ~ .read-more-wrap {
  max-height: 999em; /* Adjust this value to control the expanded height */
  transition: max-height 0.5s ease;
}

.read-more-trigger:before {
  content: 'Find Out More Here';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Close Text Panel';
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 8px;
  color: #fff;
  font-size: 12;
  line-height: 2;
  border: 1px solid #fff;
  background-color: #E1232A;
  border-radius: 25px;
}

span{
  text-align: left;
}


/* Slider */
#slider{
  width:100%;
  height:500px;
  position:relative;
  overflow:hidden;
}
@-webkit-keyframes load{
  from{left:-100%;}
  to{left:0;}
}
@keyframes load{
  from{left:-100%;}
  to{left:0;}
}
.slides{
  width:400%;
  height:100%;
  position:relative;
  -webkit-animation:slide 30s infinite;
  animation:slide 30s infinite;
}
.slider{
  width:25%;
  height:100%;
  float:left;
  position:relative;
  z-index:1;
  overflow:hidden;
}
.slide img{
  width:100%;
  height:100%;
}
.slide img{
  width:100%;
  height:100%;
}
.image{
  width:100%;
  height:100%;
}
.image img{
  width:100%;
  height:auto;
}

/* Legend */
.legend{
  border:500px solid transparent;
  border-left:800px solid rgba(94, 52, 52, 0.123);
  border-bottom:0;
  position:absolute;
  bottom:0;
}

/* Contents */
.content{
  width:100%;
  height:100%;
  position:absolute;
  overflow:hidden;
}
.content-txt{
  width:400px;
  height:150px;
  float:left;
  position:relative;
  top:300px;
  -webkit-animation:content-s 7.5s infinite;
  animation:content-s 7.5s infinite;
}
.content-txt h1{
  font-family:Arial;
  text-transform:uppercase;
  font-size:24px;
  color:#fff;
  text-align:left;
  margin-left:30px;
  padding-bottom:10px;
}
.content-txt h2{
  font-family:arial;
  font-weight:normal;
  font-size:14px;
  font-style:italic;
  color:#fff;
  text-align:left;
  margin-left:30px;
}

/* Switch */
.switch{
  width:120px;
  height:10px;
  position:absolute;
  bottom:50px;
  z-index:99;
  left:30px;
}
.switch > ul{
  list-style:none;
}
.switch > ul > li{
  width:10px;
  height:10px;
  border-radius:50%;
  background:#333;
  float:left;
  margin-right:5px;
  cursor:pointer;
}
.switch ul{
  overflow:hidden;
}
.on{
  width:100%;
  height:100%;
  border-radius:50%;
  background:#f39c12;
  position:relative;
  -webkit-animation:on 30s infinite;
  animation:on 30s infinite;
}

/* Animation */
@-webkit-keyframes slide{
  0%,100%{
    margin-left:0%;
  }
  21%{
    margin-left:0%;
  }
  25%{
    margin-left:-100%;
  }
  46%{
    margin-left:-100%;
  }
  50%{
    margin-left:-200%;
  }
  71%{
    margin-left:-200%;
  }
  75%{
    margin-left:-300%;
  }
  96%{
    margin-left:-300%;
  }
}
@keyframes slide{
  0%,100%{
    margin-left:0%;
  }
  21%{
    margin-left:0%;
  }
  25%{
    margin-left:-100%;
  }
  46%{
    margin-left:-100%;
  }
  50%{
    margin-left:-200%;
  }
  71%{
    margin-left:-200%;
  }
  75%{
    margin-left:-300%;
  }
  96%{
    margin-left:-300%;
  }
}

@-webkit-keyframes content-s{
  0%{left:-420px;}
  10%{left:0px;}
  30%{left:0px;}
  40%{left:0px;}
  50%{left:0px;}
  60%{left:0px;}
  70%{left:0;}
  80%{left:-420px;}
  90%{left:-420px;}
  100%{left:-420px;}
}
@keyframes content-s{
  0%{left:-420px;}
  10%{left:20px;}
  15%{left:0px;}
  30%{left:0px;}
  40%{left:0px;}
  50%{left:0px;}
  60%{left:0px;}
  70%{left:0;}
  80%{left:-420px;}
  90%{left:-420px;}
  100%{left:-420px;}
}

@-webkit-keyframes on{
  0%,100%{
    margin-left:0%;
  }
  21%{
    margin-left:0%;
  }
  25%{
    margin-left:15px;
  }
  46%{
    margin-left:15px;
  }
  50%{
    margin-left:30px;
  }
  71%{
    margin-left:30px;
  }
  75%{
    margin-left:45px;
  }
  96%{
    margin-left:45px;
  }
}

@keyframes on{
  0%,100%{
    margin-left:0%;
  }
  21%{
    margin-left:0%;
  }
  25%{
    margin-left:15px;
  }
  46%{
    margin-left:15px;
  }
  50%{
    margin-left:30px;
  }
  71%{
    margin-left:30px;
  }
  75%{
    margin-left:45px;
  }
  96%{
    margin-left:45px;
  }
}

.box {
  color: white;
  width: 45em;
  height: 13em;
  background: tomato;
  position: relative;
  overflow: hidden;
  margin: 2.5em auto;
  box-shadow: 0.35em 0.35em 0 0 #000;
}
.left {
  padding: 3em;
  height: 100%;
  width: 100%;
}
.left h1 {
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
}
.left p {
  font-size: 1.2em;
  width: 18em;
  margin: 0;
}
.right {
  height: 100%;
  width: 100%;
  background: #383838;
  position: absolute;
  top: 0;
  left: 28em;
  transition: 0.25s;
}
.right::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13em 3em 0 0;
  border-color: transparent #383838 transparent transparent;
  left: -3em;
  top: 0;
  position: absolute;
}
.right:hover {
  transition: 0.25s;
  -webkit-transform: translateX(-28em);
          transform: translateX(-28em);
}
.right:hover a {
  transition: 0.25s;
  -webkit-transform: translateX(0) scale(1.5);
          transform: translateX(0) scale(1.5);
}
.right:hover a::before {
  width: 4.9em;
}
.right a {
  font-weight: 700;
  text-decoration: none;
  color: white;
  text-align: center;
  display: block;
  font-size: 2.1em;
  -webkit-transform: translateX(-6.75em);
          transform: translateX(-6.75em);
  transition: 0.25s;
  position: relative;
  line-height: 6.5em;
  text-transform: uppercase;
}
.right a::after {
  content: '';
  height: 0.09em;
  position: absolute;
  background: white;
  bottom: 4em;
  left: 7.75em;
  transition: 0.25s;
  width: 0;
}
.right a::before {
  content: '';
  height: 0.1em;
  width: 0;
  position: absolute;
  background: white;
  bottom: 2.5em;
  transition: 0.25s;
}
.right a:hover::before, .right a:hover::after {
  width: 6em;
  transition: 0.25s;
}
.aboutustext h1{
color:#000;

}


.tc-dark-orange {
  color: #E1232A;
}

.tc-blue {
  color: #E1232A;
}

.tc-orange {
  color: #E1232A;
}

.tc-light-purple {
  color: #E1232A;
}


/*background colors*/

.bg-dark-orange {
  background-color: #E1232A;
  color:#FFF!important;
}

.bg-blue {
  background-color: #E1232A;
  color:#FFF!important;
}

.bg-orange {
  background-color: #E1232A;
  color:#FFF!important;
}

.bg-light-purple {
  background-color: #E1232A;
  color:#FFF!important;
}


/*fonts*/

.lobster {
  font-family: 'Lobster Two', cursive;
}

.rambla {
  font-family: 'Rambla', sans-serif;
}


/*#GRIDS*/

.grid-x5--cards {
  width: 20.5%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
}

.grid-x5--cards {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
}

.cards-cover {
  display: block;
  width: 80%;
  padding-left: 100px;
  margin: 0 auto;
  min-height: 410px;
  position: relative;
}

.cards-cover .grid-x5--cards:nth-child(1) {
  position: absolute;
  display: block;
  margin-top: 30px;
  left: 0;
  z-index: 1;
  transition: all 350ms;
}

.cards-cover .grid-x5--cards:nth-child(2) {
  position: absolute;
  margin-top: 20px;
  left: 20%;
  z-index: 2;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 350ms;
}

.cards-cover .grid-x5--cards:nth-child(3) {
  position: absolute;
  margin-top: 10px;
  left: 40%;
  z-index: 3;
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
  transition: all 350ms;
}

.cards-cover .grid-x5--cards:nth-child(4) {
  position: absolute;
  margin-top: 20px;
  left: 60%;
  z-index: 2;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 350ms;
}

.cards-cover .grid-x5--cards:nth-child(5) {
  position: absolute;
  margin-top: 30px;
  left: 80%;
  z-index: 1;
  transition: all 350ms;
}

.cards {
  vertical-align: top;
  transition: all 750ms;
  border: 1px solid #4a4a4a;
  max-height: 450px;
  margin-right: 1.5%;
  overflow: hidden;
  font-size: 14px;
  position: absolute;
  transition: all 350ms;
  background-color: #fff;
  border-radius: 10px;

}

.cards p {
  text-align: center;
  color: #000;
}

.cards img {
  width: 100%;
  object-fit: cover;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  margin: 0 auto;
  display: block;
  border-bottom: 0px solid currentColor;
  transition: all 350ms;
  border-radius: 50%;
}

.cards h2 {
  text-align: center;
  font-family: 'Lobster Two', cursive;
  transition: all 350ms;
  color: currentColor;
}

.cards a {
  text-decoration: none;
  padding: 5px 10px;
  text-align: center;
  color: white;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: all 350ms;
}

.cards-cover .grid-x5--cards:hover, .cards:hover {
  box-shadow: 0 10px 15px 6px rgba(0, 0, 0, 0.2);
  border-color: currentColor;
}

.cards:hover img {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  border-bottom: 8px solid currentColor;
  width: 102%;
}

.cards:hover h2 {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.cards:hover a {
  box-shadow: -1px 0px 15px 6px rgba(0, 0, 0, 0.3) inset;
}

@media only screen and (max-width: 768px) {
  /* Adjust font sizes for smaller screens */
  .cards p {
    font-size: 12px;
    display:none;
  }

  .cards h2 {
    font-size: 18px;
    display:none;
  }

  /* Adjust grid and card styles for smaller screens */
  .cards-cover {
    width: 100%;
    padding-left: 0;
    display:none;
  }

  .grid-x5--cards {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    display:none;
  }

  .cards {
    width: 100%;
    min-height: 250px;
    position: relative;
    left: auto !important; /* Add this to override left property */
    -webkit-transform: none !important;
            transform: none !important; /* Add this to override transform property */
  }

  .cards img {
    border-radius: 0;
    display:none;
  }
}
.custom-modal {
  width: 90%;
  max-width: 1000px; /* Adjust as needed */
  margin: 0 auto;
}

.modal-header {
  text-align: center;
}

.modal-body {
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.song-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin: 0;
  background: #ffffff;
  color: #000;
  border-radius: 0.4em;
  overflow: hidden;
  width: 100%;
}

.song-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 1em;
  border: 1px solid #ddd;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
}

.song-item:hover {
  background: #f0f0f0;
}

.song-title {
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.artist-name {
  display: none; /* Hide by default */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.7); /* Black with slight transparency */
  color: #ffffff; /* White text */
  font-style: italic;
  z-index: 2; /* Make sure it is above the song title */
  text-align: center;
}

.song-item:hover .artist-name {
  display: block; /* Show on hover */
}

@media (min-width: 480px) {
  .song-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .song-item {
    grid-template-columns: 1fr;
    -webkit-align-items: center;
            align-items: center;
    padding: 1em;
    border: none;
  }
}

.bannerimagemain {
  width:100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: auto; /* center the image horizontally */
  border-radius: 12px;
  margin-top: -14px;
}
@media only screen and (max-width: 600px) {
  .bannerimagemain {
    display:none!important;
  }
}
.l-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  max-width: 1200px;
  padding: 30px;
  margin: 0 auto; /* Center the cards */
}

@media screen and (max-width: 760px) {
  .l-container {
    grid-template-columns: repeat(1, 1fr); /* Change to 1 column */
    padding: 0 15px; /* Add padding to avoid edge-to-edge */
  }
}

.b-game-card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 150%;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.b-game-card__cover {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  will-change: transform;
  -webkit-transform: skewX(0.001deg);
          transform: skewX(0.001deg);
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}

.b-game-card__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
}

.b-game-card__title,
.b-game-card__subtitle,
.b-game-card__description {
  color: white;
}

.b-game-card__title {
  font-size: 1.5em;
  margin-bottom: 5px;
}

.b-game-card__subtitle {
  font-size: 1em;
  margin-bottom: 10px;
}

.b-game-card__description {
  font-size: 0.9em;
  line-height: 1.4;
}

.b-game-card:hover .b-game-card__cover {
  -webkit-transform: rotateX(7deg) translateY(-6px);
          transform: rotateX(7deg) translateY(-6px);
}

.b-game-card::before {
  display: block;
  content: '';
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 6px 12px 12px rgba(0, 0, 0, 0.4);
  will-change: opacity;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: skewX(0.001deg);
          transform: skewX(0.001deg);
  transition: opacity 0.5s ease-in-out, -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
  transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out, -webkit-transform 0.35s ease-in-out;
}

.b-game-card:hover::before {
  opacity: 0.6;
  -webkit-transform: rotateX(7deg) translateY(-6px) scale(1.05);
          transform: rotateX(7deg) translateY(-6px) scale(1.05);
}

.buttoncontainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; /* Center items horizontally */
}

.button-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; /* Stack buttons vertically by default */
}

/* Media query for mobile devices */
@media (min-width: 768px) {
  .button-wrapper {
    -webkit-flex-direction: row;
            flex-direction: row; /* Arrange buttons side by side */
  }
}

.share-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.share-button i {
  color: #fff;
}

.share-button.facebook {
  background-color: #3b5998;
}

.share-button.twitter {
  background-color: #1da1f2;
}

.share-button.instagram {
  background-color: #e1306c;
}

.share-button:hover {
  background-color: #ccc;
}

.share-button.facebook:hover {
  background-color: #2d4373;
}

.share-button.twitter:hover {
  background-color: #0c85d0;
}

.share-button.instagram:hover {
  background-color: #c1235b;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 150px;
  grid-gap: 5px;
  gap: 5px;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px;
}

@media (max-width: 768px) {
  .image-gallery {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 100px;
    grid-gap: 5px;
    gap: 5px;
  }
}

.media-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.media-container:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.gallery-media {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;  /* This line ensures the top part of the image is shown */
  border-radius: 5px;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.gallery123{
  background-color: #e1e5ea;
  border-radius: 12px;
}


.gallery1234{
  width: 100%;
  background-color: #e1e5ea;
  border-radius: 12px;
  padding: 24px;
}

.gallerymain{
  border-radius: 12px;
   }


@media (max-width: 768px) {
  .gallerymain{
 display: none;
  }
}
.custom-iframe {
  width: 95%;
  max-width: 95%;
  display: block;
  margin: 0 auto;
  border-radius: 10px; /* Adjust the value as needed */
}
.Gear-video-container {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.Gear-video-player {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.Gear-video-title {
  font-size: 1.5rem;
  color: #333;
  margin-top: 10px;
}

.Gear-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;  /* Centers horizontally */
  -webkit-align-items: center;
          align-items: center;      /* Centers vertically */
  height: 100%;            /* Makes the div take full viewport height */
  padding-left: 20px;
  margin-top: 60px;
}

.Gear-videoblock {
  width: 100%;               /* Adjust the size of the video block */
  max-width: 600px;          /* Ensures video doesn’t exceed 600px */
}
@media (max-width: 768px) {
  .Gear-container {
    width: 90%;              /* Wider on smaller screens */ 
  margin: 5px;
  margin-left: 15px;
  margin-top: 0px;
  }
}
@-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  .blog-card2 .description h6 {
    margin-bottom: 0.5rem; /* Adjust this value to reduce the gap */
}

.blog-card2 .description ul {
    margin-top: 0.5rem; /* Adjust this value to reduce the gap */
}
.blog-card2 {
    display: -webkit-flex;
    display: flex;
    width:100%!important;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, .1);
    background: #fff;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
}
.blog-card2 a {
    color: #274C79;
}
.blog-card2 a:hover {
    color: #274C79;
}
.blog-card2:hover .photo {
    -webkit-transform: scale(1.1) rotate(3deg);
            transform: scale(1.1) rotate(3deg);
    transition: -webkit-transform 5s;
    transition: transform 5s;
    transition: transform 5s, -webkit-transform 5s;
}
.blog-card2 .meta {
    position: relative;
    z-index: 0;
    height: 200px;
}
.blog-card2 .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: -webkit-transform 15s;
    transition: transform 15s;
    transition: transform 15s, -webkit-transform 15s;
}
.blog-card2 .details,
.blog-card2 .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
}
.blog-card2 .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left 0.2s;
    background: rgba(0, 0, 0, .6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
}
.blog-card2 .details a {
    -webkit-text-decoration: dotted underline;
            text-decoration: dotted underline;
}
.blog-card2 .details ul li {
    display: inline-block;
}
.blog-card2 .details .author:before {
    margin-right: 10px;
}
.blog-card2 .details .date:before {
    margin-right: 10px;
}
.blog-card2 .details .tags ul:before {
    margin-right: 10px;
}
.blog-card2 .details .tags li {
    margin-right: 2px;
}
.blog-card2 .details .tags li:first-child {
    margin-left: -4px;
}
.blog-card2 .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
}
.blog-card2 .description h1,
.blog-card2 .description h2 {
    font-family: Poppins, sans-serif;
}
.blog-card2 .description h2 {
    line-height: 1;
    margin: 0;
    font-size: 1.7rem;
}
.blog-card2 .description h3 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #a2a2a2;
    margin-top: 5px;
}
.blog-card2 .description .read-more {
    text-align: right;
}
.blog-card2 .description .read-more a {
    color: #5ad67d;
    display: inline-block;
    position: relative;
}
.blog-card2 .description .read-more a:after {
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
    transition: margin 0.3s, opacity 0.3s;
}
.blog-card2 .description .read-more a:hover:after {
    margin-left: 5px;
    opacity: 1;
}
.blog-card2 p {
    position: relative;
    margin: 1rem 0 0;
}
.blog-card2 p:first-of-type {
    margin-top: 1.25rem;
}
.blog-card2 p:before {
    content: "";
    position: absolute;
    height: 5px;
    background: #274C79;
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
}
.blog-card2:hover .details {
    left: 0%;
}
@media (min-width: 900px) {
    .blog-card2 {
        -webkit-flex-direction: row;
                flex-direction: row;
        max-width: 100%;
    }
    .blog-card2 .meta {
        -webkit-flex-basis: 40%;
                flex-basis: 40%;
        height: auto;
    }
    .blog-card2 .description {
        -webkit-flex-basis: 60%;
                flex-basis: 60%;
    }
    .blog-card2 .description:before {
        -webkit-transform: skewX(-3deg);
                transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    .blog-card2.alt {
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
    }
    .blog-card2.alt .description:before {
        left: inherit;
        right: -10px;
        -webkit-transform: skew(3deg);
                transform: skew(3deg);
    }
    .blog-card2.alt .details {
        padding-left: 25px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.tabs {
    width: 80%;
    height: 100px;
    margin: auto;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.tabs li {
    background-color: #8f909100;
    width: 25%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #c4cfde;
    font-family: 'Poppins', sans-serif;
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    cursor: pointer;
}

.tabs li:hover {
    background: linear-gradient(145deg, #fff, #fff);
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    color: #E1232A;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.active {
    background: linear-gradient(145deg, #fff, #fff);
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    color: #E1232A !important;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.contents {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-gap: 30px;
    gap: 30px;
}

.box1 {
    grid-gap: 20px;
    gap: 20px;
    background: linear-gradient(145deg, #fff, #fff);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px;
    width: 100%;
    animation: moving 1s ease;
    -webkit-animation: moving 1s ease;
}

.box1 img {
    width: 50%;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.box1 h3 {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
}

.box1 p {
    color: #000;
    opacity: .5;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.show {
    display: -webkit-flex;
    display: flex;
}

.hide {
    display: none;
}

@-webkit-keyframes moving {
    from {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes moving {
    from {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        opacity: 1;
    }
}


#pageHeaderTitle {
    margin: 2rem 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5rem;
}
/* Cards */
.postcard {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #fff;
    width:100%;
}
.postcard.light {
    background-color: #e1e5ea;
}
.postcard .t-dark {
    color: #18151f;
}
.postcard a {
    color: inherit;
}
.postcard h1, .postcard .h1 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.postcard .small {
    font-size: 80%;
}
.postcard .postcard__title {
    font-size: 1.75rem;
    margin-left: 40px;
    color:#000;
}
.postcard .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    background-position: center 15%;
    position: relative;
}
.postcard .postcard__img_link {
    display: contents;
}
.postcard .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
    margin-left: 40px;
}
.postcard .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.postcard .postcard__preview-txt {
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    text-align: left;
    height: 100%;
    margin-left: 40px;
}
.postcard .postcard__preview-txt p {
    font-size: 14px;
}
.postcard .postcard__tagbox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
    padding: 0;
    -webkit-justify-content: center;
            justify-content: center;
}
.postcard .postcard__tagbox .tag__item {
    display: inline-block;
    background: #E1232A;
    box-shadow: 1px 1px 1px #1c1e22, -1px -1px 1px #262a2e;
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: background-color 0.3s;
    margin-left:15px;
    color: #FFF;
}
.postcard .postcard__tagbox .tag__item:hover {
    background: rgba(255, 255, 255, 0.274);
    box-shadow: 1px 1px 1px #E1232A, -1px -1px 1px #E1232A;
    color: #000;
}
.postcard:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 1;
    border-radius: 10px;
}
.postcard:hover .postcard__bar {
    width: 100px;
}
@media screen and (min-width: 769px) {
    .postcard {
        -webkit-flex-wrap: inherit;
                flex-wrap: inherit;
   }
    .postcard .postcard__title {
        font-size: 2rem;
   }
    .postcard .postcard__tagbox {
        -webkit-justify-content: start;
                justify-content: start;
   }
    .postcard .postcard__img {
        max-width: 300px;
        max-height: 100%;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
   }
   .postcard .postcard__text {
       width: 100%;
  }
    .postcard .media.postcard__text:before {
        content: "";
        position: absolute;
        display: block;
        background: #18151f;
        top: -20%;
        height: 130%;
        width: 55px;
   }
    .postcard:hover .postcard__img {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
   }
    .postcard:nth-child(2n+1) {
        -webkit-flex-direction: row;
                flex-direction: row;
   }
    .postcard:nth-child(2n+0) {
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
   }
    .postcard:nth-child(2n+1) .postcard__text::before {
        left: -12px !important;
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
   }
    .postcard:nth-child(2n+0) .postcard__text::before {
        right: -12px !important;
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
   }
}
@media screen and (min-width: 1024px) {
    .postcard__text {
        padding: 2rem 3.5rem;
   }
    .postcard__text:before {
        content: "";
        position: absolute;
        display: block;
        top: -20%;
        height: 130%;
        width: 55px;
   }
    .postcard.dark .postcard__text:before {
        background: #18151f;
   }
    .postcard.light .postcard__text:before {
        background: #e1e5ea;
   }
}
/* COLORS */
.postcard .postcard__tagbox .green.play:hover {
    background: #79dd09;
    color: black;
}
.green .postcard__title:hover {
    color: #79dd09;
}
.green .postcard__bar {
    background-color: #79dd09;
}
.green::before {
    background-image: linear-gradient(-30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}
.green:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .blue.play:hover {
    background: #E1232A;
}
.blue .postcard__title:hover {
    color: #E1232A;
}
.blue .postcard__bar {
    background-color: #E1232A;
}
.blue::before {
    background-image: linear-gradient(-30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
.blue:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .red.play:hover {
    background: #E1232A;
}
.red .postcard__title:hover {
    color: #E1232A;
}
.red .postcard__bar {
    background-color: #E1232A;
}
.red::before {
    background-image: linear-gradient(-30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
.red:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .yellow.play:hover {
    background: #E1232A;
    color: black;
}
.yellow .postcard__title:hover {
    color: #E1232A;
}
.yellow .postcard__bar {
    background-color: #E1232A;
}
.yellow::before {
    background-image: linear-gradient(-30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
.yellow:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
@media screen and (min-width: 769px) {
    .green::before {
        background-image: linear-gradient(-80deg, rgba(121, 221, 9, 0.1), transparent 50%);
   }
    .green:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(121, 221, 9, 0.1), transparent 50%);
   }
    .blue::before {
        background-image: linear-gradient(-80deg, rgba(0, 118, 189, 0.1), transparent 50%);
   }
    .blue:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(0, 118, 189, 0.1), transparent 50%);
   }
    .red::before {
        background-image: linear-gradient(-80deg, rgba(189, 21, 11, 0.1), transparent 50%);
   }
    .red:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(189, 21, 11, 0.1), transparent 50%);
   }
    .yellow::before {
        background-image: linear-gradient(-80deg, rgba(189, 187, 73, 0.1), transparent 50%);
   }
    .yellow:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(189, 187, 73, 0.1), transparent 50%);
   }
}

.sliderimg{

    width: 70%;
    border-radius: 12px;
    margin: 0 auto; /* Center the content horizontally */
}/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
    .sliderimg {
        width: 100%; /* Make it full width on mobile */
        height: 110px;
        margin-top: 25px;
        
    }
}

.donateblurb{
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    color: #000000;
}

.donateslider{
    border-radius: 12px!important;
}
@media only screen and (max-width: 600px) {
    .donateslider {
        width: 100%; /* Make it full width on mobile */
        
    }
}


.donateslider1{
    border-radius: 12px!important;
    width: 100%!important;
}
@media only screen and (max-width: 600px) {
    .donateslider1 {
        width: 100%; /* Make it full width on mobile */
        
    }
}

/* SidePopoutTab.css */

/* Styles for the container */
.side-tab-container {
    position: fixed;
    right: -360px; /* Default position when closed */
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 12px 0 0 12px;
    overflow: hidden;
  }
  
  .side-tab-container {
    position: fixed;
    right: -300px; /* Default position when closed */
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 12px 0 0 12px;
    overflow: hidden;
}

.side-tab-container.open {
    right: 0; /* Adjust to show part of the tab */
}

.tab-button {
    background-color: #3b5998;
    color: white;
    border: none;
    border-radius: 12px 0 0 12px; /* Curved towards the slider */
    padding: 10px;
    position: absolute;
    right: 100%; /* Positioning it on the left side of the slider */
    top: 50%; /* Centered vertically */
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    z-index: 1001; /* Ensure it's above the slider */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.tab-button .icon {
    font-size: 20px; /* Size of the icon */
    color: white; /* Icon color */
}

.tab-button:hover {
    background-color: #3b5998;
}

.facebook-feed {
    width: 300px; /* Width of the feed */
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 12px 0 0 12px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 500px;
    left: 330px;
    background-color: #3b5998;
    color: white;
    border: none;
    border-radius: 12%;
    padding: 5px;
    cursor: pointer;
    z-index: 1002; /* Ensure it's above the feed content */
}

.close-button .icon {
    font-size: 20px; /* Size of the icon */
    color: white; /* Icon color */
}
  
  @media (max-width: 600px) {
    .side-tab-container {
      top: 0;
      -webkit-transform: none;
              transform: none;
      width: 100vw; /* Full viewport width */
      max-width: 400px; /* Adjust max-width for mobile */
      right: -360px; /* Default position when closed */
      border-radius: 12px;
    }
  
    .side-tab-container.open {
        right: -5px; /* Position when open on mobile */
        bottom: -40px; /* Position when open on mobile */
    }
  
  
    .side-tab-container.closed {
        right: -540px; /* Position when open on mobile */
        bottom: -40px; /* Position when open on mobile */

      }
    
    .facebook-feed {
      width: 100%;
      max-width: 100%;
    }
  
    .tab-button {
      position: fixed; /* Ensure the button is visible on mobile */
      right: 0; /* Positioned at the edge of the screen */
      top: 50%; /* Centered vertically */
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: 1001; /* Above the slider */
    }
  }
  

  
.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }
  
  /* 5 */
  .btn-5 {
    width: 200px;
    height: 60px;
    line-height: 42px;
    padding: 0;
    border: none;
    background: #E1232A;
  background: linear-gradient(0deg, #E1232A 0%, #751c1f 100%);
  font-size: 22px;
  }
  .custom-btn .btn-5 span {
    display: inline-block;
    width: 75%;
  }
  .btn-5:hover {
    color: #E1232A;
    background: transparent;
     box-shadow:none;
     font-size: 24px;
  }
  .btn-5:before,
  .btn-5:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #E1232A;
    box-shadow:
     -1px -1px 5px 0px #fff,
     7px 7px 20px 0px #0003,
     4px 4px 5px 0px #0002;
    transition:400ms ease all;
  }
  .btn-5:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  .btn-5:hover:before,
  .btn-5:hover:after{
    width:100%;
    transition:800ms ease all;
  }
  
  .modal-412 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content-412 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100000;
    max-height: 80vh; /* Reduce vertical height */
    overflow-y: auto; /* Enable scrolling if content overflows */
  }
  
  .close-412 {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close-412:hover,
  .close-412:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .header-412 {
    text-align: center;
    margin-bottom: 10px;
    color: black !important;
    font-size: 24px; /* Adjust the size as needed */
  }
  
  .subtext-412 {
    font-size: 12px !important;
    font-style: italic;
    text-align: center;
    color: black !important;
  }
  
  .form-row-412 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .form-group-412 {
    -webkit-flex: 1 1;
            flex: 1 1;
    min-width: calc(50% - 10px);
  }
  
  .label-412 {
    display: block;
    color: black !important;
    margin-bottom: 5px;
  }
  
  .input-412,
  .textarea-412,
  .select-412 {
    width: 100%;
    padding: 10px;
    margin: 5px 0 10px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .status-message-412 {
    text-align: center;
    margin: 10px 0;
  }
  
  .success-412 {
    color: green;
  }
  
  .error-412 {
    color: #E1232A;
  }
  
  .submit-button-412 {
    width: 50%;
    margin: 8px auto; /* Center the button */
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #E1232A;
    box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003, 4px 4px 5px 0px #0002;
    transition: 400ms ease all;
    display: block; /* Ensure it's a block element for centering */
  }
  
  .submit-button-412:hover {
    background-color: #E1232A;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  @media (max-width: 600px) {
    .form-row-412 {
      -webkit-flex-direction: row;
              flex-direction: row; /* Keep fields side by side on mobile */
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; /* Allow wrapping to the next line if needed */
    }
  
    .form-group-412 {
      min-width: calc(50% - 10px); /* Adjust width for mobile */
      margin-bottom: 10px; /* Ensure spacing between rows */
    }
  
    .header-412 {
      font-size: 20px; /* Adjust the size as needed for mobile */
    }
  
    .subtext-412 {
      font-style: italic;
      text-align: center;
      font-size: 12px; /* Adjust the size as needed for mobile */
    }
  
    .modal-content-412 {
      max-height: 70vh; /* Reduce vertical height for mobile */
      overflow-y: auto; /* Enable scrolling if content overflows */
    }
  
    .submit-button-412 {
      width: 100%; /* Full width on mobile */
    }
  }
  
  .music-icon-button {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: white!important;
    padding: 10px 20px;
    margin-bottom: -38px!important;
    transition: background-color 0.3s, -webkit-transform 0.3s;
    transition: background-color 0.3s, transform 0.3s;
    transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
  }
  
  .music-icon {
    margin-right: 8px;
  }
  
  .musicbutton-text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .music-icon-button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  
  
  .music-icon-button2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: rgb(0, 0, 0)!important;
    padding: 10px 20px;
    margin-bottom: -38px!important;
    transition: background-color 0.3s, -webkit-transform 0.3s;
    transition: background-color 0.3s, transform 0.3s;
    transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
  }
  
  .music-icon2 {
    margin-right: 8px;
    color: rgb(0, 0, 0)!important;
  }
  
  .musicbutton-text2 {
    color: rgb(0, 0, 0)!important;
    font-size: 16px;
    font-weight: bold;
  }
  
  .music-icon-button:hover2 {
    color: rgb(0, 0, 0)!important;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .btn11-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px auto; /* Center the button */
    display: block;    /* Ensure the button is centered */
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 12px;
    transition: all .4s ease-in-out;
}

.btn11-hover:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.btn11-hover:focus {
    outline: none;
}

.btn11-hover.color-1 {
    background-image: linear-gradient(to right, #e1232a, #cd3333, #b22222, #8b0000);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.75);
} 
/* Mobile-specific styles */
@media (max-width: 768px) {
    .btn11-hover {
        width: 80%; /* Adjust the button to 50% width on mobile devices */
        font-size: 10px;
        color: #fff;
    }
    
}
.b-game-card__cover {
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
  }
  /* Alert.css */
@-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
@keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  .alert {
    width: 50%;
    padding: 20px;
    background-color: #e1232a;
    color: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 16px;
    position: relative;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation: popUp 0.5s ease-out forwards;
            animation: popUp 0.5s ease-out forwards;
    margin: 0 auto;
    text-align: center;
  }
  
  .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    transition: color 0.3s ease, -webkit-transform 0.3s ease;
    transition: color 0.3s ease, transform 0.3s ease;
    transition: color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .closebtn:hover {
    color: #f1faee;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* Alert.css */
@keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  .alert {
    width: 50%;
    padding: 20px;
    background-color: #e1232a;
    color: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 16px;
    position: relative;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation: popUp 0.5s ease-out forwards;
            animation: popUp 0.5s ease-out forwards;
    margin: 0 auto;
    text-align: center;
  }
  
  .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    transition: color 0.3s ease, -webkit-transform 0.3s ease;
    transition: color 0.3s ease, transform 0.3s ease;
    transition: color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .closebtn:hover {
    color: #f1faee;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  /* Responsive design for tablet screens */
  @media (max-width: 768px) {
    .alert {
      width: 70%; /* Adjust width for tablets */
      font-size: 14px; /* Slightly smaller font size */
      padding: 15px; /* Adjust padding */
    }
  
    .closebtn {
      font-size: 20px; /* Adjust close button size */
    }
  }
  
  /* Responsive design for mobile screens */
  @media (max-width: 600px) {
    .alert {
      width: 90%; /* Alert takes most of the screen width on mobile */
      font-size: 10px; /* Smaller font for mobile */
      padding: 10px; /* Smaller padding for mobile */
    }
  
    .closebtn {
      font-size: 18px; /* Smaller font size for close button */
      top: 5px;
      right: 10px; /* Ensure button does not overlap content */
    }
  }
  .youtube-playlist-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
  
  .main-video {
    width: 100%; /* Ensure the parent div is full width */
    position: relative; /* Needed for responsive height */
    padding-top: 56.25%; /* Aspect ratio for 16:9 */
  }
  
  .main-video iframe {
    position: absolute; /* Ensures the iframe fills the parent div */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-thumbnails {
    display: -webkit-flex;
    display: flex;
    overflow-x: auto;
    width: 100%;
    background-color: #f9f9f9;
    padding: 10px 0;
    border-radius: 12px;
    margin-bottom: 24px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .thumbnail {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 180px; /* Make thumbnails wider */
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  
  .thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .thumbnail img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  
  .thumbnail p {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
  }
  
  .youtube-playlist-container .main-video .video-frame {
    border-radius: 24px;
  }
  

#slider {
  overflow: hidden;
  height: 100%;
  margin-bottom: 50px;
  border-radius: 12px;
}

@media only screen and (max-width: 768px) {
  #slider {
      height: 95px;
      margin-top: 50px;
      margin-bottom: 20px;
  }
}

#slider figure {
  position: relative;
  width: 300%; /* Adjusted width based on the number of images */
  margin: 0;
  left: 0;
  text-align: left;
}

#slider figure img {
  float: left;
  width: 33.3333%; /* Adjusted width based on the number of images */
  height: 100%;
}

/* Animation for modal */
@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
@keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
  .customModal {
    -webkit-animation: fadeIn 0.5s ease-in-out;
            animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Make sure the modal is responsive */
  .customOverlay {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  /* Style for the modal header to keep the layout consistent */
  .modal-header {
    text-align: center;
    padding: 20px;
  }
  
  /* Style for the body section */
  .modal-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 20px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 600px) {
    .modal-header h3 {
      font-size: 22px;
    }
    .modal-body img {
      width: 120px;
      height: 120px;
    }
    p {
      font-size: 14px;
    }
  }
  
  /* General styles for the modal image */
.modal-body img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #E1232A;
    float: left;
    margin-right: 15px;
  }
  
  /* Hide the image on mobile screens */
  @media (max-width: 600px) {
    .modal-body img {
      display: none;
    }
  
    /* Adjust the text alignment to fill the space */
    .modal-body p {
      text-align: left;
      text-indent: 0;
      margin-left: 0;
    }
  }

  .our-team {
    padding: 30px;
    margin: 30px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  
  .our-team .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 100%; /* Set this to 100% to expand the background */
    border-radius: 50%;
    background-color: #E1232A;
    position: absolute;
    bottom: 0; /* Ensure it's aligned properly */
    right: 0;
    left: 0;
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); /* Keep the scale as 1 so it's the normal size */
    transition: none; /* Remove the transition to keep it static */
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #E1232A;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    -webkit-transform: scale(1);
            transform: scale(1); /* Keep it scaled */
    box-shadow: 0 0 0 14px #E1232A; /* Keep the shadow effect */
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: #000000;
    text-transform: capitalize;
  }
  
  .our-team h3 {
    display: block;
    font-size: 15px;
    color: #000000;
    text-transform: capitalize;
  }
  
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #E1232A;
    position: absolute;
    bottom: 0; /* Keep social section in the visible position */
    left: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color: #000000;
    text-decoration: none;
  }
  
  .our-team .social li a:hover {
    color: #E1232A;
    background-color: #000000;
  }
  
  /* Hide container12322 on screens larger than 768px (tablet and desktop) */
  .container12322 {
    display: none;
  }
  
  /* Show container12322 only on mobile devices (screens less than or equal to 768px) */
  @media (max-width: 768px) {
    .container12322 {
      display: block;
    }
  }
  
.blog-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

@media (min-width: 600px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .blog-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.blog-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.blog-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-card__img-container {
  width: 100%;
  overflow: hidden;
}

.blog-card__img {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
}

.blog-card__content {
  padding: 20px;
  color: #333; /* Set default text color */
}

.blog-card__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000!important; /* Set title text color */
}

.blog-card__subtitle {
  font-size: 0.9rem;
  color: #777; /* Set subtitle text color */
  margin-bottom: 10px;
}

.blog-card__bar {
  height: 4px;
  width: 50px;
  background: #e74c3c;
  margin-bottom: 10px;
}

.blog-card__preview-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333; /* Set preview text color */
  margin-bottom: 20px;
}

.blog-card__button {
  border-radius: 50px;
  width: 100%;
  border: 0;
  color: white;
  height: 40px;
  background-color: #E1232A;
  transition: all ease-in-out 0.3s;
}

.blog-card__button:hover {
  cursor: pointer;
  background-color: #E1232A;
  transition: all ease-in-out 0.3s;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 50%;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 80vh;
}

@media (max-width: 600px) {
  .modal-content {
    max-width: 80%!important;
  }
}
.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.modal-header img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.modal-subtitle {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.modal-body {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

@media (min-width: 600px) {
  .modal-header {
    -webkit-flex-direction: row;
            flex-direction: row;
    text-align: left;
  }

  .modal-header img {
    width: 50%;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .modal-header .text-content {
    width: 50%;
  }
}

/* Mp3Player.css */
.mp3-player {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 750px; /* Maximum width for larger screens */
  padding: 16px;
  background-image: url("/static/media/Rockin @Music Series B-W@ Gan.36ab7d12.jpg"); /* Replace with your background image path */
  background-size: cover; /* Cover the entire player area */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating the background image */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); /* Added a slightly darker shadow for more contrast */
  position: relative;
  margin: auto; /* Center horizontally */
  border-radius: 12px;
}

.mp3-player .title {
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap; /* Prevent title from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Ellipsis for overflowing text */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.mp3-player .player-controls {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; /* Allow controls to wrap on smaller screens */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 16px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.mp3-player .player-controls .MuiButton-root {
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.mp3-player .player-controls .MuiButton-root:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.volume-control {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 16px;
  width: 100%; /* Ensure volume control takes full width */
  -webkit-justify-content: center;
          justify-content: center; /* Center the volume control */
}

.volume-control .MuiSlider-root {
  width: 80%; /* Adjust width of the slider */
  margin-left: 8px;
  color: #ffffff; /* Ensure the slider thumb and track are visible */
}

@media (max-width: 600px) {
  .mp3-player {
    padding: 12px;
  }
  .mp3-player  {
    width: 80%;
  }

  .mp3-player .title {
    font-size: 0.9rem;
  }

  .volume-control .MuiSlider-root {
    width: 90%; /* Make slider wider on small screens */
  }
}

