.blog-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

@media (min-width: 600px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .blog-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.blog-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.blog-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-card__img-container {
  width: 100%;
  overflow: hidden;
}

.blog-card__img {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
}

.blog-card__content {
  padding: 20px;
  color: #333; /* Set default text color */
}

.blog-card__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000!important; /* Set title text color */
}

.blog-card__subtitle {
  font-size: 0.9rem;
  color: #777; /* Set subtitle text color */
  margin-bottom: 10px;
}

.blog-card__bar {
  height: 4px;
  width: 50px;
  background: #e74c3c;
  margin-bottom: 10px;
}

.blog-card__preview-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333; /* Set preview text color */
  margin-bottom: 20px;
}

.blog-card__button {
  border-radius: 50px;
  width: 100%;
  border: 0;
  color: white;
  height: 40px;
  background-color: #E1232A;
  transition: all ease-in-out 0.3s;
}

.blog-card__button:hover {
  cursor: pointer;
  background-color: #E1232A;
  transition: all ease-in-out 0.3s;
  transform: scale(1.02);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 50%;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 80vh;
}

@media (max-width: 600px) {
  .modal-content {
    max-width: 80%!important;
  }
}
.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modal-header img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.modal-subtitle {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.modal-body {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

@media (min-width: 600px) {
  .modal-header {
    flex-direction: row;
    text-align: left;
  }

  .modal-header img {
    width: 50%;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .modal-header .text-content {
    width: 50%;
  }
}
