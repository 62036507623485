
#slider {
  overflow: hidden;
  height: 100%;
  margin-bottom: 50px;
  border-radius: 12px;
}

@media only screen and (max-width: 768px) {
  #slider {
      height: 95px;
      margin-top: 50px;
      margin-bottom: 20px;
  }
}

#slider figure {
  position: relative;
  width: 300%; /* Adjusted width based on the number of images */
  margin: 0;
  left: 0;
  text-align: left;
}

#slider figure img {
  float: left;
  width: 33.3333%; /* Adjusted width based on the number of images */
  height: 100%;
}
