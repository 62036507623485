@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .blog-card2 .description h6 {
    margin-bottom: 0.5rem; /* Adjust this value to reduce the gap */
}

.blog-card2 .description ul {
    margin-top: 0.5rem; /* Adjust this value to reduce the gap */
}
.blog-card2 {
    display: flex;
    width:100%!important;
    flex-direction: column;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, .1);
    background: #fff;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
}
.blog-card2 a {
    color: #274C79;
}
.blog-card2 a:hover {
    color: #274C79;
}
.blog-card2:hover .photo {
    transform: scale(1.1) rotate(3deg);
    transition: transform 5s;
}
.blog-card2 .meta {
    position: relative;
    z-index: 0;
    height: 200px;
}
.blog-card2 .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 15s;
}
.blog-card2 .details,
.blog-card2 .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
}
.blog-card2 .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left 0.2s;
    background: rgba(0, 0, 0, .6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
}
.blog-card2 .details a {
    text-decoration: dotted underline;
}
.blog-card2 .details ul li {
    display: inline-block;
}
.blog-card2 .details .author:before {
    margin-right: 10px;
}
.blog-card2 .details .date:before {
    margin-right: 10px;
}
.blog-card2 .details .tags ul:before {
    margin-right: 10px;
}
.blog-card2 .details .tags li {
    margin-right: 2px;
}
.blog-card2 .details .tags li:first-child {
    margin-left: -4px;
}
.blog-card2 .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
}
.blog-card2 .description h1,
.blog-card2 .description h2 {
    font-family: Poppins, sans-serif;
}
.blog-card2 .description h2 {
    line-height: 1;
    margin: 0;
    font-size: 1.7rem;
}
.blog-card2 .description h3 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #a2a2a2;
    margin-top: 5px;
}
.blog-card2 .description .read-more {
    text-align: right;
}
.blog-card2 .description .read-more a {
    color: #5ad67d;
    display: inline-block;
    position: relative;
}
.blog-card2 .description .read-more a:after {
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
    transition: margin 0.3s, opacity 0.3s;
}
.blog-card2 .description .read-more a:hover:after {
    margin-left: 5px;
    opacity: 1;
}
.blog-card2 p {
    position: relative;
    margin: 1rem 0 0;
}
.blog-card2 p:first-of-type {
    margin-top: 1.25rem;
}
.blog-card2 p:before {
    content: "";
    position: absolute;
    height: 5px;
    background: #274C79;
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
}
.blog-card2:hover .details {
    left: 0%;
}
@media (min-width: 900px) {
    .blog-card2 {
        flex-direction: row;
        max-width: 100%;
    }
    .blog-card2 .meta {
        flex-basis: 40%;
        height: auto;
    }
    .blog-card2 .description {
        flex-basis: 60%;
    }
    .blog-card2 .description:before {
        transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    .blog-card2.alt {
        flex-direction: row-reverse;
    }
    .blog-card2.alt .description:before {
        left: inherit;
        right: -10px;
        transform: skew(3deg);
    }
    .blog-card2.alt .details {
        padding-left: 25px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.tabs {
    width: 80%;
    height: 100px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.tabs li {
    background-color: #8f909100;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c4cfde;
    font-family: 'Poppins', sans-serif;
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    cursor: pointer;
}

.tabs li:hover {
    background: linear-gradient(145deg, #fff, #fff);
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    color: #E1232A;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.active {
    background: linear-gradient(145deg, #fff, #fff);
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    color: #E1232A !important;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.contents {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
}

.box1 {
    gap: 20px;
    background: linear-gradient(145deg, #fff, #fff);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px;
    width: 100%;
    animation: moving 1s ease;
    -webkit-animation: moving 1s ease;
}

.box1 img {
    width: 50%;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.box1 h3 {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
}

.box1 p {
    color: #000;
    opacity: .5;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.show {
    display: flex;
}

.hide {
    display: none;
}

@keyframes moving {
    from {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        opacity: 1;
    }
}


#pageHeaderTitle {
    margin: 2rem 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5rem;
}
/* Cards */
.postcard {
    flex-wrap: wrap;
    display: flex;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #fff;
    width:100%;
}
.postcard.light {
    background-color: #e1e5ea;
}
.postcard .t-dark {
    color: #18151f;
}
.postcard a {
    color: inherit;
}
.postcard h1, .postcard .h1 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.postcard .small {
    font-size: 80%;
}
.postcard .postcard__title {
    font-size: 1.75rem;
    margin-left: 40px;
    color:#000;
}
.postcard .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    background-position: center 15%;
    position: relative;
}
.postcard .postcard__img_link {
    display: contents;
}
.postcard .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
    margin-left: 40px;
}
.postcard .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
}
.postcard .postcard__preview-txt {
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    text-align: left;
    height: 100%;
    margin-left: 40px;
}
.postcard .postcard__preview-txt p {
    font-size: 14px;
}
.postcard .postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
    padding: 0;
    justify-content: center;
}
.postcard .postcard__tagbox .tag__item {
    display: inline-block;
    background: #E1232A;
    box-shadow: 1px 1px 1px #1c1e22, -1px -1px 1px #262a2e;
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    user-select: none;
    transition: background-color 0.3s;
    margin-left:15px;
    color: #FFF;
}
.postcard .postcard__tagbox .tag__item:hover {
    background: rgba(255, 255, 255, 0.274);
    box-shadow: 1px 1px 1px #E1232A, -1px -1px 1px #E1232A;
    color: #000;
}
.postcard:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 1;
    border-radius: 10px;
}
.postcard:hover .postcard__bar {
    width: 100px;
}
@media screen and (min-width: 769px) {
    .postcard {
        flex-wrap: inherit;
   }
    .postcard .postcard__title {
        font-size: 2rem;
   }
    .postcard .postcard__tagbox {
        justify-content: start;
   }
    .postcard .postcard__img {
        max-width: 300px;
        max-height: 100%;
        transition: transform 0.3s ease;
   }
   .postcard .postcard__text {
       width: 100%;
  }
    .postcard .media.postcard__text:before {
        content: "";
        position: absolute;
        display: block;
        background: #18151f;
        top: -20%;
        height: 130%;
        width: 55px;
   }
    .postcard:hover .postcard__img {
        transform: scale(1.1);
   }
    .postcard:nth-child(2n+1) {
        flex-direction: row;
   }
    .postcard:nth-child(2n+0) {
        flex-direction: row-reverse;
   }
    .postcard:nth-child(2n+1) .postcard__text::before {
        left: -12px !important;
        transform: rotate(4deg);
   }
    .postcard:nth-child(2n+0) .postcard__text::before {
        right: -12px !important;
        transform: rotate(-4deg);
   }
}
@media screen and (min-width: 1024px) {
    .postcard__text {
        padding: 2rem 3.5rem;
   }
    .postcard__text:before {
        content: "";
        position: absolute;
        display: block;
        top: -20%;
        height: 130%;
        width: 55px;
   }
    .postcard.dark .postcard__text:before {
        background: #18151f;
   }
    .postcard.light .postcard__text:before {
        background: #e1e5ea;
   }
}
/* COLORS */
.postcard .postcard__tagbox .green.play:hover {
    background: #79dd09;
    color: black;
}
.green .postcard__title:hover {
    color: #79dd09;
}
.green .postcard__bar {
    background-color: #79dd09;
}
.green::before {
    background-image: linear-gradient(-30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}
.green:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .blue.play:hover {
    background: #E1232A;
}
.blue .postcard__title:hover {
    color: #E1232A;
}
.blue .postcard__bar {
    background-color: #E1232A;
}
.blue::before {
    background-image: linear-gradient(-30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
.blue:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .red.play:hover {
    background: #E1232A;
}
.red .postcard__title:hover {
    color: #E1232A;
}
.red .postcard__bar {
    background-color: #E1232A;
}
.red::before {
    background-image: linear-gradient(-30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
.red:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .yellow.play:hover {
    background: #E1232A;
    color: black;
}
.yellow .postcard__title:hover {
    color: #E1232A;
}
.yellow .postcard__bar {
    background-color: #E1232A;
}
.yellow::before {
    background-image: linear-gradient(-30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
.yellow:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
@media screen and (min-width: 769px) {
    .green::before {
        background-image: linear-gradient(-80deg, rgba(121, 221, 9, 0.1), transparent 50%);
   }
    .green:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(121, 221, 9, 0.1), transparent 50%);
   }
    .blue::before {
        background-image: linear-gradient(-80deg, rgba(0, 118, 189, 0.1), transparent 50%);
   }
    .blue:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(0, 118, 189, 0.1), transparent 50%);
   }
    .red::before {
        background-image: linear-gradient(-80deg, rgba(189, 21, 11, 0.1), transparent 50%);
   }
    .red:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(189, 21, 11, 0.1), transparent 50%);
   }
    .yellow::before {
        background-image: linear-gradient(-80deg, rgba(189, 187, 73, 0.1), transparent 50%);
   }
    .yellow:nth-child(2n)::before {
        background-image: linear-gradient(80deg, rgba(189, 187, 73, 0.1), transparent 50%);
   }
}

.sliderimg{

    width: 70%;
    border-radius: 12px;
    margin: 0 auto; /* Center the content horizontally */
}/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
    .sliderimg {
        width: 100%; /* Make it full width on mobile */
        height: 110px;
        margin-top: 25px;
        
    }
}

.donateblurb{
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    color: #000000;
}

.donateslider{
    border-radius: 12px!important;
}
@media only screen and (max-width: 600px) {
    .donateslider {
        width: 100%; /* Make it full width on mobile */
        
    }
}


.donateslider1{
    border-radius: 12px!important;
    width: 100%!important;
}
@media only screen and (max-width: 600px) {
    .donateslider1 {
        width: 100%; /* Make it full width on mobile */
        
    }
}

/* SidePopoutTab.css */

/* Styles for the container */
.side-tab-container {
    position: fixed;
    right: -360px; /* Default position when closed */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    overflow: hidden;
  }
  
  .side-tab-container {
    position: fixed;
    right: -300px; /* Default position when closed */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    overflow: hidden;
}

.side-tab-container.open {
    right: 0; /* Adjust to show part of the tab */
}

.tab-button {
    background-color: #3b5998;
    color: white;
    border: none;
    border-radius: 12px 0 0 12px; /* Curved towards the slider */
    padding: 10px;
    position: absolute;
    right: 100%; /* Positioning it on the left side of the slider */
    top: 50%; /* Centered vertically */
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1001; /* Ensure it's above the slider */
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-button .icon {
    font-size: 20px; /* Size of the icon */
    color: white; /* Icon color */
}

.tab-button:hover {
    background-color: #3b5998;
}

.facebook-feed {
    width: 300px; /* Width of the feed */
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 12px 0 0 12px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 500px;
    left: 330px;
    background-color: #3b5998;
    color: white;
    border: none;
    border-radius: 12%;
    padding: 5px;
    cursor: pointer;
    z-index: 1002; /* Ensure it's above the feed content */
}

.close-button .icon {
    font-size: 20px; /* Size of the icon */
    color: white; /* Icon color */
}
  
  @media (max-width: 600px) {
    .side-tab-container {
      top: 0;
      transform: none;
      width: 100vw; /* Full viewport width */
      max-width: 400px; /* Adjust max-width for mobile */
      right: -360px; /* Default position when closed */
      border-radius: 12px;
    }
  
    .side-tab-container.open {
        right: -5px; /* Position when open on mobile */
        bottom: -40px; /* Position when open on mobile */
    }
  
  
    .side-tab-container.closed {
        right: -540px; /* Position when open on mobile */
        bottom: -40px; /* Position when open on mobile */

      }
    
    .facebook-feed {
      width: 100%;
      max-width: 100%;
    }
  
    .tab-button {
      position: fixed; /* Ensure the button is visible on mobile */
      right: 0; /* Positioned at the edge of the screen */
      top: 50%; /* Centered vertically */
      transform: translateY(-50%);
      z-index: 1001; /* Above the slider */
    }
  }
  

  
.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }
  
  /* 5 */
  .btn-5 {
    width: 200px;
    height: 60px;
    line-height: 42px;
    padding: 0;
    border: none;
    background: #E1232A;
  background: linear-gradient(0deg, #E1232A 0%, #751c1f 100%);
  font-size: 22px;
  }
  .custom-btn .btn-5 span {
    display: inline-block;
    width: 75%;
  }
  .btn-5:hover {
    color: #E1232A;
    background: transparent;
     box-shadow:none;
     font-size: 24px;
  }
  .btn-5:before,
  .btn-5:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #E1232A;
    box-shadow:
     -1px -1px 5px 0px #fff,
     7px 7px 20px 0px #0003,
     4px 4px 5px 0px #0002;
    transition:400ms ease all;
  }
  .btn-5:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  .btn-5:hover:before,
  .btn-5:hover:after{
    width:100%;
    transition:800ms ease all;
  }
  
  .modal-412 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content-412 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100000;
    max-height: 80vh; /* Reduce vertical height */
    overflow-y: auto; /* Enable scrolling if content overflows */
  }
  
  .close-412 {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close-412:hover,
  .close-412:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .header-412 {
    text-align: center;
    margin-bottom: 10px;
    color: black !important;
    font-size: 24px; /* Adjust the size as needed */
  }
  
  .subtext-412 {
    font-size: 12px !important;
    font-style: italic;
    text-align: center;
    color: black !important;
  }
  
  .form-row-412 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .form-group-412 {
    flex: 1;
    min-width: calc(50% - 10px);
  }
  
  .label-412 {
    display: block;
    color: black !important;
    margin-bottom: 5px;
  }
  
  .input-412,
  .textarea-412,
  .select-412 {
    width: 100%;
    padding: 10px;
    margin: 5px 0 10px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .status-message-412 {
    text-align: center;
    margin: 10px 0;
  }
  
  .success-412 {
    color: green;
  }
  
  .error-412 {
    color: #E1232A;
  }
  
  .submit-button-412 {
    width: 50%;
    margin: 8px auto; /* Center the button */
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #E1232A;
    box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003, 4px 4px 5px 0px #0002;
    transition: 400ms ease all;
    display: block; /* Ensure it's a block element for centering */
  }
  
  .submit-button-412:hover {
    background-color: #E1232A;
    transform: scale(1.1);
  }
  
  @media (max-width: 600px) {
    .form-row-412 {
      flex-direction: row; /* Keep fields side by side on mobile */
      flex-wrap: wrap; /* Allow wrapping to the next line if needed */
    }
  
    .form-group-412 {
      min-width: calc(50% - 10px); /* Adjust width for mobile */
      margin-bottom: 10px; /* Ensure spacing between rows */
    }
  
    .header-412 {
      font-size: 20px; /* Adjust the size as needed for mobile */
    }
  
    .subtext-412 {
      font-style: italic;
      text-align: center;
      font-size: 12px; /* Adjust the size as needed for mobile */
    }
  
    .modal-content-412 {
      max-height: 70vh; /* Reduce vertical height for mobile */
      overflow-y: auto; /* Enable scrolling if content overflows */
    }
  
    .submit-button-412 {
      width: 100%; /* Full width on mobile */
    }
  }
  
  .music-icon-button {
    display: flex;
    align-items: center;
    color: white!important;
    padding: 10px 20px;
    margin-bottom: -38px!important;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .music-icon {
    margin-right: 8px;
  }
  
  .musicbutton-text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .music-icon-button:hover {
    transform: scale(1.05);
  }
  
  
  .music-icon-button2 {
    display: flex;
    align-items: center;
    color: rgb(0, 0, 0)!important;
    padding: 10px 20px;
    margin-bottom: -38px!important;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .music-icon2 {
    margin-right: 8px;
    color: rgb(0, 0, 0)!important;
  }
  
  .musicbutton-text2 {
    color: rgb(0, 0, 0)!important;
    font-size: 16px;
    font-weight: bold;
  }
  
  .music-icon-button:hover2 {
    color: rgb(0, 0, 0)!important;
    transform: scale(1.05);
  }
  .btn11-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px auto; /* Center the button */
    display: block;    /* Ensure the button is centered */
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 12px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn11-hover:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    transform: scale(1.05);
}

.btn11-hover:focus {
    outline: none;
}

.btn11-hover.color-1 {
    background-image: linear-gradient(to right, #e1232a, #cd3333, #b22222, #8b0000);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.75);
} 
/* Mobile-specific styles */
@media (max-width: 768px) {
    .btn11-hover {
        width: 80%; /* Adjust the button to 50% width on mobile devices */
        font-size: 10px;
        color: #fff;
    }
    
}
.b-game-card__cover {
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
  }
  /* Alert.css */
@keyframes popUp {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .alert {
    width: 50%;
    padding: 20px;
    background-color: #e1232a;
    color: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 16px;
    position: relative;
    opacity: 0;
    transform: scale(0);
    animation: popUp 0.5s ease-out forwards;
    margin: 0 auto;
    text-align: center;
  }
  
  .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .closebtn:hover {
    color: #f1faee;
    transform: scale(1.1);
  }
  
  /* Alert.css */
@keyframes popUp {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .alert {
    width: 50%;
    padding: 20px;
    background-color: #e1232a;
    color: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 16px;
    position: relative;
    opacity: 0;
    transform: scale(0);
    animation: popUp 0.5s ease-out forwards;
    margin: 0 auto;
    text-align: center;
  }
  
  .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .closebtn:hover {
    color: #f1faee;
    transform: scale(1.1);
  }
  
  /* Responsive design for tablet screens */
  @media (max-width: 768px) {
    .alert {
      width: 70%; /* Adjust width for tablets */
      font-size: 14px; /* Slightly smaller font size */
      padding: 15px; /* Adjust padding */
    }
  
    .closebtn {
      font-size: 20px; /* Adjust close button size */
    }
  }
  
  /* Responsive design for mobile screens */
  @media (max-width: 600px) {
    .alert {
      width: 90%; /* Alert takes most of the screen width on mobile */
      font-size: 10px; /* Smaller font for mobile */
      padding: 10px; /* Smaller padding for mobile */
    }
  
    .closebtn {
      font-size: 18px; /* Smaller font size for close button */
      top: 5px;
      right: 10px; /* Ensure button does not overlap content */
    }
  }
  .youtube-playlist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .main-video {
    width: 100%; /* Ensure the parent div is full width */
    position: relative; /* Needed for responsive height */
    padding-top: 56.25%; /* Aspect ratio for 16:9 */
  }
  
  .main-video iframe {
    position: absolute; /* Ensures the iframe fills the parent div */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-thumbnails {
    display: flex;
    overflow-x: auto;
    width: 100%;
    background-color: #f9f9f9;
    padding: 10px 0;
    border-radius: 12px;
    margin-bottom: 24px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .thumbnail {
    flex: 0 0 auto;
    width: 180px; /* Make thumbnails wider */
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  
  .thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .thumbnail img:hover {
    transform: scale(1.05);
  }
  
  .thumbnail p {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
  }
  
  .youtube-playlist-container .main-video .video-frame {
    border-radius: 24px;
  }
  