/* Mp3Player.css */
.mp3-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px; /* Maximum width for larger screens */
  padding: 16px;
  background-image: url('../../src/assets/images/showimages/Rockin\ @Music\ Series\ B-W@\ Gan.jpg'); /* Replace with your background image path */
  background-size: cover; /* Cover the entire player area */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating the background image */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); /* Added a slightly darker shadow for more contrast */
  position: relative;
  margin: auto; /* Center horizontally */
  border-radius: 12px;
}

.mp3-player .title {
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap; /* Prevent title from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Ellipsis for overflowing text */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.mp3-player .player-controls {
  display: flex;
  flex-wrap: wrap; /* Allow controls to wrap on smaller screens */
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.mp3-player .player-controls .MuiButton-root {
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.mp3-player .player-controls .MuiButton-root:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Added text shadow for contrast */
}

.volume-control {
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%; /* Ensure volume control takes full width */
  justify-content: center; /* Center the volume control */
}

.volume-control .MuiSlider-root {
  width: 80%; /* Adjust width of the slider */
  margin-left: 8px;
  color: #ffffff; /* Ensure the slider thumb and track are visible */
}

@media (max-width: 600px) {
  .mp3-player {
    padding: 12px;
  }
  .mp3-player  {
    width: 80%;
  }

  .mp3-player .title {
    font-size: 0.9rem;
  }

  .volume-control .MuiSlider-root {
    width: 90%; /* Make slider wider on small screens */
  }
}
