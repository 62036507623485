/* Animation for modal */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .customModal {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Make sure the modal is responsive */
  .customOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Style for the modal header to keep the layout consistent */
  .modal-header {
    text-align: center;
    padding: 20px;
  }
  
  /* Style for the body section */
  .modal-body {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 600px) {
    .modal-header h3 {
      font-size: 22px;
    }
    .modal-body img {
      width: 120px;
      height: 120px;
    }
    p {
      font-size: 14px;
    }
  }
  
  /* General styles for the modal image */
.modal-body img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #E1232A;
    float: left;
    margin-right: 15px;
  }
  
  /* Hide the image on mobile screens */
  @media (max-width: 600px) {
    .modal-body img {
      display: none;
    }
  
    /* Adjust the text alignment to fill the space */
    .modal-body p {
      text-align: left;
      text-indent: 0;
      margin-left: 0;
    }
  }

  .our-team {
    padding: 30px;
    margin: 30px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  
  .our-team .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 100%; /* Set this to 100% to expand the background */
    border-radius: 50%;
    background-color: #E1232A;
    position: absolute;
    bottom: 0; /* Ensure it's aligned properly */
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(1); /* Keep the scale as 1 so it's the normal size */
    transition: none; /* Remove the transition to keep it static */
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #E1232A;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1); /* Keep it scaled */
    box-shadow: 0 0 0 14px #E1232A; /* Keep the shadow effect */
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: #000000;
    text-transform: capitalize;
  }
  
  .our-team h3 {
    display: block;
    font-size: 15px;
    color: #000000;
    text-transform: capitalize;
  }
  
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #E1232A;
    position: absolute;
    bottom: 0; /* Keep social section in the visible position */
    left: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color: #000000;
    text-decoration: none;
  }
  
  .our-team .social li a:hover {
    color: #E1232A;
    background-color: #000000;
  }
  
  /* Hide container12322 on screens larger than 768px (tablet and desktop) */
  .container12322 {
    display: none;
  }
  
  /* Show container12322 only on mobile devices (screens less than or equal to 768px) */
  @media (max-width: 768px) {
    .container12322 {
      display: block;
    }
  }
  